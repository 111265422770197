import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import img from "../../Assets/cardImg.webp"
import { Avatar } from "@material-ui/core";
//styles imort
import { userList } from "../../Redux/Auth/actions"
import Dashboard from "../dashboard";

import styles from "./style.module.scss";
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';

export default function Profile() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer?.profileData?.data);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(userList())
  }, [])
  return (
    <div className={`container ${styles.profileMain}`}>
      <div className="row">
        <div className="col-lg-4 mb-5">
          <Card sx={{ borderRadius: 5, border: 1, borderColor: "03D069" }}>
            <div className='row px-3'>
              <CardContent sx={{ padding: 1 }}>
                <div className='row justify-content-center'>
                  <div className="col-lg-12 float-end">
                    <Button
                      component={Link}
                      to={'/EditProfile'}
                      className={`float-end my-4 ${styles.editBUtton}`}
                    >
                      Edit Profile
                    </Button>
                  </div>
                  <Avatar
                    className={styles.avatar}
                    src={img}
                  />
                  <div className={styles.name}>
                    {userData?.firstName ? userData?.firstName : "N/A"}
                  </div>
                  <div className={styles.email}>
                    {userData?.email ? userData?.email : "N/A"}
                  </div>
                </div>
              </CardContent>
            </div>
          </Card>

        </div>
        <div className="col-lg-8">
          <Card sx={{ borderRadius: 5, border: 1, borderColor: "03D069" }}>
            <div className='row px-3'>
              <CardContent sx={{ padding: 1 }}>
               <Dashboard />
              </CardContent>
            </div>
          </Card>

        </div>
      </div>

    </div >
  );
}