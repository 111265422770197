import React from "react";
import LoginForm from "../../Components/LoginForm";

//style here
import styles from "./style.module.scss";
export default function ActionAreaCard({ title, subHeading, type }) {
  return (
    <div className="row mx-0">
      <div className={`col-lg-12 text-center ${styles.loginHeader}`}>
      <h2>{title ? title :""}</h2>
      </div>
      <LoginForm />
      </div>
  );
}
