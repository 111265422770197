import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import metaMaskImg from "../../Assets/metamask.png";
import coinBaseImg from "../../Assets/coinbase.png";
import trustWalletImg from "../../Assets/trustwallet.png";
import { Button, Card, Col, Form, Row, Toast } from "react-bootstrap";
import { toast } from "react-toastify";
//styles imort
import styles from "./style.module.scss";
import { editUserProfile } from "../../Redux/Card/actions";
import { ethers } from "ethers";

import WalletConnectionButton from "../../Components/connectWallet/index";

export default function EditProfile() {
  const dispatch = useDispatch();
  const [btnState, setBtnState] = useState(false);
  const [signer, setSigner] = React.useState();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [address, setAddress] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };
  const userData = useSelector((state) => state.authReducer?.profileData?.data);
  const buySchema = Yup.object().shape({
    firstName: Yup.string().trim().required("Please enter firstName"),
    // lastName: Yup.string().trim().required("Please enter address"),
    address: Yup.string().trim().required("Please enter address"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: userData?.firstName,
      // lastName: userData?.lastName,
      address: userData?.address,
      walletAddress: userData?.walletAddress,
    },
    enableReinitialize: true,
    validationSchema: buySchema,
    onSubmit: (values) => {
      let data = {
        firstName: values.firstName,
        // lastName: values.lastName,
        address: values.address,
        walletAddress: address,
      };
      if (address) {
        dispatch(
          editUserProfile({
            data: data,
            resetForm: formik.resetForm,
          })
        );
      } else {
        toast.error("Please connect wallet first");
      }
    },
  });

  const trustWalletConnection = async () => {
    try {
      setLoader(true);
      if (window.trustwallet.isTrustWallet) {
        // Get provider from Trustwallet
        const provider = new ethers.providers.Web3Provider(window.trustwallet);
        const signers = provider.getSigner();
        // Set signer
        const network = provider.getNetwork();
        if ((await network).chainId !== 398) {
          await window.trustwallet.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${Number(398).toString(16)}`,
                chainName: "Argon",
                rpcUrls: ["https://rpc-mainnet.argonteq.com/"],
                nativeCurrency: {
                  name: "ARG",
                  symbol: "ARG",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://scan.argonteq.com/"],
              },
            ],
          });
        }
        const accounts = await provider.send("eth_requestAccounts", []);
        const account = await signers.getAddress();
        setSigner(signers);
        setAddress(account);
        setLoader(false);
      } else {
        toast.error("Please install TrustWallet Extension");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const coinbaseWalletConnection = async () => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setOpen(false);
    if (mediaQuery.matches === true) {
      if (window.ethereum) {
        await window.ethereum.enable();
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signers = provider.getSigner();
        // Set signer
        const network = provider.getNetwork();
        if ((await network).chainId !== 398) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${Number(398).toString(16)}`,
                chainName: "Argon",
                rpcUrls: ["https://rpc-mainnet.argonteq.com/"],
                nativeCurrency: {
                  name: "ARG",
                  symbol: "ARG",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://scan.argonteq.com/"],
              },
            ],
          });
        }
        const account = await signers.getAddress();
        setSigner(signers);
        setAddress(account);
        setLoader(false);
      } else {
        const url = "https://go.cb-w.com/dapp?cb_url=carmammas.com";
        window.location.href = url;
      }
    } else {
      try {
        setLoader(true);
        if (window.coinbaseWalletExtension) {
          // Get provider from Coinbase wallet
          const provider = new ethers.providers.Web3Provider(
            window.coinbaseWalletExtension
          );
          const signers = provider.getSigner();
          // Set signer
          const network = provider.getNetwork();
          if ((await network).chainId !== 398) {
            await window.coinbaseWalletExtension.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${Number(398).toString(16)}`,
                  chainName: "Argon",
                  rpcUrls: ["https://rpc-mainnet.argonteq.com/"],
                  nativeCurrency: {
                    name: "ARG",
                    symbol: "ARG",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://scan.argonteq.com/"],
                },
              ],
            });
          }
          const account = await signers.getAddress();
          setSigner(signers);
          setAddress(account);
          setLoader(false);
        } else {
          toast.error("Please install CoinbaseWallet Extension");
        }
      } catch (err) {
        toast.error(err);
      }
    }
  };
  const metamaskConnection = async () => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setOpen(false);
    if (mediaQuery.matches === true) {
      if (window.ethereum) {
        await window.ethereum.enable();
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signers = provider.getSigner();
        // Set signer
        const network = provider.getNetwork();
        if ((await network).chainId !== 398) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${Number(398).toString(16)}`,
                chainName: "Argon",
                rpcUrls: ["https://rpc-mainnet.argonteq.com/"],
                nativeCurrency: {
                  name: "ARG",
                  symbol: "ARG",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://scan.argonteq.com/"],
              },
            ],
          });
        }
        const account = await signers.getAddress();
        setSigner(signers);
        setAddress(account);
        setLoader(false);
      } else {
        const url = "https://metamask.app.link/dapp/carmammas.com";
        window.location.href = url;
      }
    } else {
      try {
        setLoader(true);
        if (window.ethereum) {
          // Get provider from Metamask
          await window.ethereum.enable();
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signers = provider.getSigner();
          // Set signer
          const network = provider.getNetwork();
          if ((await network).chainId !== 398) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${Number(398).toString(16)}`,
                  chainName: "Argon",
                  rpcUrls: ["https://rpc-mainnet.argonteq.com/"],
                  nativeCurrency: {
                    name: "ARG",
                    symbol: "ARG",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://scan.argonteq.com/"],
                },
              ],
            });
          }
          const account = await signers.getAddress();
          setSigner(signers);
          setAddress(account);
          setLoader(false);
        } else {
          window.open("https://metamask.io/download/", "_blank");
        }
      } catch (err) {
        toast.error(err);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (loader) {
      setOpen(false);
    }
  }, [loader]);
  return (
    <div className={`container ${styles.mainForm}`}>
      <form onSubmit={formik.handleSubmit} className={styles.Form}>
        {/* {loader ? (
          <Spinner />
        ) : <> */}
        <CardHeader title="Edit Profile" style={{ justifyContent: "center" }} />
        <div className="row mx-0">
          <Col md={12} className="mb-3">
            <Form.Group id="Car Info">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                // required
                className={styles.inputDesig}
                type="text"
                placeholder="Full Name"
                value={formik.values.firstName}
                name="Car Info"
                label="firstName"
                onChange={(e) => {
                  formik.setFieldValue("firstName", e.target.value);
                }}
              />
              {formik?.touched?.firstName && formik?.errors?.firstName ? (
                <div style={{ color: "red" }}>{formik?.errors?.firstName}</div>
              ) : null}
            </Form.Group>
          </Col>
          {/* <Col md={12} className="mb-3">
            <Form.Group id="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                // required
                type="text"
                placeholder="Last Name"
                value={formik.values.lastName}
                name="lastName"
                label="lastName"
                onChange={(e) => {
                  formik.setFieldValue("lastName", e.target.value);
                }}
              />
              {formik.touched?.lastName && formik.errors?.lastName ? (
                <div style={{ color: "red" }}>{formik.errors?.lastName}</div>
              ) : null}
            </Form.Group>
          </Col> */}

          <Col md={12} className="mb-3">
            <Form.Group id="address">
              <Form.Label>Residential Address</Form.Label>
              <Form.Control
                fullWidth
                type="text"
                placeholder="Address"
                value={formik.values.address}
                name="address"
                label="address"
                onChange={(e) => {
                  formik.setFieldValue("address", e.target.value);
                }}
              />
              {formik.touched.address && formik.errors.address ? (
                <div style={{ color: "red" }}>{formik.errors.address}</div>
              ) : null}
            </Form.Group>
          </Col>

          {address === undefined ? (
            <Button onClick={handleOpen}>Connect wallet</Button>
          ) : (
            <Button>
              {" "}
              {address.slice(0, 5) + "..." + address.slice(37, 42)}{" "}
            </Button>
          )}

          {/* <div className="col-lg-12 pb-5"> */}
          <Button type="submit" className="my-4" disabled={btnState}>
            Done
          </Button>
          {/* </div> */}
        </div>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Wallet for connection
          </Typography>
          {/* <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={trustWalletConnection}
          >
            <img src={trustWalletImg} />
            Trust Wallet
          </Typography> */}
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={coinbaseWalletConnection}
          >
            <img src={coinBaseImg} />
            Coinbase Wallet
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={metamaskConnection}
          >
            <img src={metaMaskImg} /> Metamask
          </Typography>
          {/* <Button onClick={walletConnect} className={`mt-3 ${styles.connectedButon}`}>Connect to wallet</Button> */}
        </Box>
      </Modal>
    </div>
  );
}
