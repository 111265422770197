import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "./Card"

import { getSingleCityData } from "../../Redux/Card/actions"
import styles from "./style.module.scss"
import Spinner from "../../Components/spinner/spinner";

const AllListing = () => {
  const dispatch = useDispatch();
  let city = window.location.search.split("?")[1];
  const listData = useSelector((state) => state?.CardReducer?.singleCityData?.AllSellerAds);
  const sellerData = useSelector((state) => state?.CardReducer?.sellerList?.AllSellerAds);
  const buyerData = useSelector((state) => state?.CardReducer?.buyerList?.AllSellerAds);

  const [data, setData] = useState(listData)
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (city === "seller") {
      setData(sellerData)
    }
    if (city === "buyer") {
      setData(buyerData)
    }
    // setData(listData)
  }, [data])

  useEffect(() => {
    if (listData !== undefined) {
      if (city !== "buyer" && city !== "seller") {
        setData(listData)
      }
    }
    // setData(listData)
  }, [listData])


  useEffect(() => {
    if (city !== "buyer" && city !== "seller") {
      dispatch(getSingleCityData({
        city,
        setLoader: setLoader,
      }))
    }
    window.scrollTo(0, 0);
  }, [])
  return (
    <div className={`container ${styles.AllListing}`}>
      {
      loader ? (
        <Spinner />
      ) : <>
      <div className='row'>
        <div className={`col-12 my-5 text-center ${styles.head}`}>
          <h3>{ city === "buyer" ? "Car for Sale" : "Cars Wanted"}</h3>
          {/* <span>{city.replace(/%20/g, " ").charAt(0).toUpperCase() + city.slice(1).replace(/%20/g, " ")}</span> */}
        </div>
        {data === undefined && (
          <div className={styles.emptyList}>
            <h3>
              <span>There is not Add listing yet</span>
            </h3>
          </div>
        )}
        {data?.map((data, index) => {
          return (
            <div className='col-lg-3 my-2'>
              <Card data={data} index={index} city={city} />
            </div>
          )
        })}
      </div>
      </>
    }
    </div>

  )
}
export default AllListing;
