import React,{useEffect} from 'react'
import ResetForm from "../../Components/ResetForm/index"
import styles from "./style.module.scss"

export default function Login() {
  return (
    <div className={`container ${styles.Forms}`}>
    <ResetForm title="New Password" type ="resetPassword"/>
    </div>
  )
}
