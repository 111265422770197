import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "../../Components/Card/index";

//style

import styles from "./style.module.scss"


const NearByListing=()=> {
    const CityData = useSelector((state) => state?.CardReducer?.citiList?.AllSellerAds);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className={`container ${styles.mainNearBy}`}>
        <div className='row mx-0'>
        <div className={`col-lg-12 text-center my-5 ${styles.nearBy}`}>
                <h1>
                  <span>
                    Nearby Cars
                  </span>
                </h1>
                {/* <span>Discover best cars near where you live
                </span> */}
              </div>
        {CityData?.map((item, index) => {
                return (
                  <div className="col-lg-3 col-md-4 my-2">
                    <Card data={item} index={index} />
                  </div>
                )
              })}
        </div>
    </div>
  )

}

export default NearByListing;
