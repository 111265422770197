import React from 'react'
import SignInForm from "./signIn"
import ForgetForm from "./forgetPassword"
import ForgotUsername from "./forgotUsername"
import styles from "./style.module.scss"

export default function SignIn() {
  return (
    <div className={`container ${styles.Forms}`}>
        <SignInForm title="Already a Member"/>
    </div>
  )
}
