import React, {useEffect} from "react";
import Faq from "react-faq-component";
import styles from "./style.module.scss"

const FaqComponent = () => {
    const data = {
        title: "FAQ (How it works)",
        rows: [
            {
                title: "How to Connect with Wallet",
                content: `1. First go to following link, <a to ="https://carmammas.com/signup">https://carmammas.com/signup</a>  
                </br> 
                2. Enter your Email </br>
                3. Enter your Full name </br>
                4. Enter your own Password </br>
                5. Then click on create account. </br>`,
            },
            {
                title: "How to sign in on carmammas.com?",
                content:`1. First go to following link, <a to ="https://carmammas.com/login">https://carmammas.com/login.</a> </br>
                2. Enter your login credentials </br>
                3. Then click on Sign in. </br>`,
            },
            {
                title: "How to Reset your password?",
                content:`1. On login page, click on Forget password</br> 
                2. Enter your Email and Click “request Email” </br>
                3. From your mails, Open the link sent by Carmammas to reset your password </br>
                4. After opening the link, Enter a new password and confirm it  </br>
                5. Then Click on Change Password. </br>`,
            },
            {
              title: "How to create/edit your profile information and register your wallet address?",
              content:`On Edit profile page, you are allowed to edit you first name, last name and Residential address, You can change it anytime </br> 
              1. After log in Click on the avatar at the top right of the page    </br>            
              2. From the drop down, Click on Profile” </br>
              3. On profile page, Click Edit Profile from the top right of the page </br>
              4. Enter your first name nad last name </br>
              5. Enter your residential address </br>
              6. Connect to your wallet (For the first time you have to register your wallet address to perform any blockchain based functionality) </br>
              7. After that, click on Done. </br>`,
          },
          {
            title: "How to connect a wallet?",
            content:`To connect your account to a wallet, there are two ways: </br> 
            1. On the Home Page, Click on Connect Wallet on the top right of the page </br>            
            2. Then from the pop-up select the crypto wallet </br>
            3. For mobile phone it should take you to crypto wallet dapp browser, On that page create a account or login with a registered one </br>
            4. Then again On Carmammas page, Click on connect wallet </br>
            5. It should give a message Wallet connected. </br>
            "Second" </br>
            1. On the Edit profile page, Click on Connect wallet </br>
            2. Then from the pop-up select the crypto wallet</br>
            3. It should take you to metamask, coinbase dapp browser, In that dapp browser create a account or login with a registered one </br>
            4. Then again On Carmammas page, Click on connect wallet </br>
            5. It should give a message Wallet connected. </br>`,
        },
        {
          title: "How to Log out?",
          content:`
          1. Click on the profile avatar from the top right of the page,  </br>            
          2. From the drop down, Select the Log out </br>
          3. Then Click on it </br>
          4. It should Log you out and redirect you to the Login page`,
      },
      {
        title: "Terms & Condition and About Us:",
        content:`How to get to the Terms & conditions page? </br>
        1. On any Page, Scroll down to the Footer section </br>            
        2. Then Select the terms & Conditions and click on it </br>
        3. It should take the user to the Terms & conditions page </br>
        How to Contact Us? </br>
        1. On the Home page, From the Navigation bar, click on “About” </br>
        2. On About Us page, Scroll down to the Contact Us section </br>
        3. Inside the Section Enter your Email address </br>
        4. Then Click on “Submit” </br>
        How to Know about our Community? </br>
        1. From the navigation bar, Click on “About” </br>
        2. It will take you to a page where you can read about our community and our aims`,
    },
    {
      title: "Selling and buying of a Car:",
      content:`How to post an ad Sell a Car? </br>
      1. After Logging in and connecting your wallet</br>            
      2. Click on “Trade” from the navigation bar </br>
      3. Then from the dropdown, Select the option saying “Sell My Car”</br>
      4. It will take you to the Sell-Car information page </br>
      5. On that page, Enter Car details </br>
      6. After that Check car features checkboxes </br>
      7. Upload high resolution pictures of your Car </br>
      8. Add your Phone number </br>
      9. Click on “Submit” </br>
      10. At this time Mmetamask will open 3 times for confirmation:
        a.  Creation of NFT </br>
        b.  Approval </br>
        c.  Listing on marketplace </br>
        How to post an ad to buy a Car? </br>
      1. After Logging in and connecting your wallet </br>
      2. Click on “Trade” from the navigation bar</br>
      3. Then from the dropdown, Select the option saying “Buy A Car” </br>
      4. It will take you to the Buy-Car information page </br>
      5. On that page, Enter Car details </br>
      6. After that check car features checkboxes </br>
      7. Upload decent pictures of your car</br>
      8. Add your Phone number </br>
      9. Click on Submit and Continue </br>
      How to Find Nearby Cars? </br>
      1. On Home page, Scroll down to the Near By Cars section </br>
      2. Click on your “City” or city nearest yours </br>
      3. It will open a page, containing only cars for sale from that particular region`,
  },
  {
    title: "Getting to Marketplace and Dashboard:",
    content:`How to get to the Marketplace? </br> 
    All Cars for sale sell will show in this section of the page, </br>            
    1. On the Home page, scroll down to Marketplace section </br>
    How can I get to my Dashboard?</br>
      Dashboard page contains data about your selled cars for sale, cars you want to buy and your purchases	</br>
    1. Click on the Profile Avatar from the top right of the page </br>
    2. From the dropdown, click on Dashboard</br>
    3. It should open a window saying User Dashboard`,
},

{
  title: "Deleting Cars from profile:",
  content:`How to delete cars from Sell Car in Profile? </br>            
        1. Click on the profile avatar, from the drop down click on profile.</br>  
        2. From the sell car section, select the car you want to delete, then Click on it.</br>  
        3. It should open the car detail page.</br>  
        4. Click on the Delete button.</br>  
        5. After that, confirm the transaction from your crypto wallet.</br>  
        Note: Cars are NFTs, when you’ll delete the car it’ll be burnt from the blockchain.</br>  
            How to delete cars from Buy Car in Profile? </br>            
              1. Click on the profile avatar, from the drop down click on profile.</br> 
              2. Click on the Buy Car section, Inside the section select the car you want to delete, then click on it.</br> 
              3. It should open the car detail page.</br> 
              4. Click on the Delete button.</br> 
              5. After that, from the pop-up confirm your action.
        `,
},
        ],
    };
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
  return (
    <div className={`container ${styles.Forms}`}>
        <div className="row mx-0">
          <div className="col-lg-12 text-center">
            <h1>~FAQ~</h1>
          </div>
        </div>
        <div className="col-lg-12">
        <Faq data={data}/>
        </div>
    </div>
  );
};
export default FaqComponent;
