import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import img from "../../Assets/cardImg.webp"
import { getCities } from "../../Redux/Card/actions"

//styles imort
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

export default function RecipeReviewCard({ data }) {
  // const dispatch = useDispatch();
  // const CityData = useSelector((state) => state.CardReducer.citiList.AllSellerAds);

  // useEffect(()=>{
  //   dispatch(getCities())
  // },[])
  return (
    <Link to={`/allListing?${data.registerIn}`} >
    <Card sx={{ maxWidth: 345, borderRadius: 5, border: 1, borderColor: "03D069" }}>
      <div className='row px-3'>
        <CardContent sx={{ padding: 1 }}>
          <div className={`col-lg-12 text-end ${styles.headerText}`}>
            <h3 className="mb-0"><span>{data?.totalAds ? data?.totalAds : 0}</span></h3>
          </div>
          <div className='row'>
            <div className={`col-lg-4 col-sm-4 ${styles.cardImage}`}>
              <CardMedia
                component="img"
                image={img}
                alt="Paella dish"
              />
            </div>
            <div className={`col-lg-8 col-sm-8 my-4 p-0 ${styles.textCard}`}>
              <Typography variant="body2" color="text.secondary" className="mt-1">
                  <h3>{data?.registerIn ? data?.registerIn.replace(/%20/g, " ") : 0}</h3>
              </Typography>
            </div>
          </div>
        </CardContent>
      </div>
    </Card>
    </Link>
  );
}