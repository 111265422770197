import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { SignUpRequest } from "../../Redux/Auth/actions";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Spinner from "../../Components/spinner/spinner";

//scss
import styles from "./style.module.scss";
import { Button } from "@mui/material";
import metaMaskImg from "../../Assets/metamask.png";
import coinBaseImg from "../../Assets/coinbase.png";
import trustWalletImg from "../../Assets/trustwallet.png";
import { toast } from "react-toastify";

const Form = ({ type, title }) => {
  const dispatch = useDispatch();
  const [btnState, setBtnState] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [signer, setSigner] = React.useState();
  const [loader, setLoader] = useState(false);
  const [address, setAddress] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };
  const loginUserSchema = Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .max(16, "Fullname should not exceed 16 characters")
      .required("Please enter full name"),
    // userName: Yup.string()
    //   .trim()
    //   .max(16, "Username should not exceed 16 characters")
    //   .required("Please enter username"),
    email: Yup.string()
      .trim()
      .email("Please enter valid email address")
      .required("Please enter email address"),
    password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .required("Please enter password"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      fullName: "",
      // userName: "",
      // walletAddress: "",
    },
    validationSchema: loginUserSchema,
    onSubmit: (values) => {
      if (true) {
        setLoader(true);
        setBtnState(true);
        dispatch(
          SignUpRequest({
            email: values.email,
            password: values.password,
            fullName: values.fullName,
            // userName: values.userName,
            // walletAddress: address,
            setBtnState: setBtnState,
            setLoader: setLoader,
          })
        );
      } else {
        toast.error("Please connect wallet");
      }
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const trustWalletConnection = async () => {
    try {
      setLoader(true);
      if (window.trustwallet.isTrustWallet) {
        // Get provider from Trustwallet
        const provider = new ethers.providers.Web3Provider(window.trustwallet);
        const signers = provider.getSigner();
        // Set signer
        const network = provider.getNetwork();
        if ((await network).chainId !== 398) {
          await window.trustwallet.request({
            method: "wallet_addEthereumChain",
            params: [{ chainId: `0x${Number(398).toString(16)}` }],
          });
        }
        const accounts = await provider.send("eth_requestAccounts", []);
        const account = await signers.getAddress();
        setSigner(signers);
        setAddress(account);
        setLoader(false);
      } else {
        toast.error("Please install TrustWallet Extension");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const coinbaseWalletConnection = async () => {
    try {
      setLoader(true);
      if (window.coinbaseWalletExtension) {
        // Get provider from Coinbase wallet
        const provider = new ethers.providers.Web3Provider(
          window.coinbaseWalletExtension
        );
        const signers = provider.getSigner();
        // Set signer
        const network = provider.getNetwork();
        if ((await network).chainId !== 398) {
          await window.coinbaseWalletExtension.request({
            method: "wallet_addEthereumChain",
            params: [{ chainId: `0x${Number(398).toString(16)}` }],
          });
        }
        const accounts = await provider.send("eth_requestAccounts", []);
        const account = await signers.getAddress();
        setSigner(signers);
        setAddress(account);
        setLoader(false);
      } else {
        toast.error("Please install CoinbaseWallet Extension");
      }
    } catch (err) {
      toast.error(err);
    }
  };
  const metamaskConnection = async () => {
    try {
      setLoader(true);
      if (window.ethereum) {
        // Get provider from Metamask
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signers = provider.getSigner();
        // Set signer
        const network = provider.getNetwork();
        if ((await network).chainId !== 398) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{ chainId: `0x${Number(398).toString(16)}` }],
          });
        }
        const account = await signers.getAddress();
        setSigner(signers);
        setAddress(account);
        setLoader(false);
      } else {
        window.open("https://metamask.io/download/", "_blank");
      }
    } catch (err) {
      toast.error(err);
    }
  };
  useEffect(() => {
    if (loader) {
      setOpen(false);
    }
  }, [loader]);

  return (
    <div className="Container">
      {loader ? (
        <Spinner />
      ) : (
        <div className="row">
          <div className="col-12">
            <h2 className={`text-center ${styles.loginHeader}`}>
              {title ? title : ""}
            </h2>
            <form onSubmit={formik.handleSubmit} className={styles.Form}>
              <label className="text-start">Email *</label>
              <input
                fullWidth
                className={styles.input}
                name="email"
                placeholder="Enter Your Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                helperText={formik.touched.email && formik.errors.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className={styles.error}>{formik.errors.email}</p>
              ) : (
                ""
              )}
              <label className="text-start">Full name *</label>
              <input
                className={styles.input}
                name="fullName"
                placeholder="Enter First name"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                helperText={formik.touched.fullName && formik.errors.fullName}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <p className={styles.error}>{formik.errors.fullName}</p>
              ) : (
                ""
              )}
              {/* <label className="text-start">Last Name</label>
              <input
                className={styles.input}
                name="userName"
                placeholder="Enter Last name"
                value={formik.values.userName}
                onChange={formik.handleChange}
                helperText={formik.touched.userName && formik.errors.userName}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <p className={styles.error}>{formik.errors.userName}</p>
              ) : (
                ""
              )} */}
              <label className="text-start">Password *</label>
              <input
                name="password"
                type="password"
                placeholder="Enter Password"
                className={styles.input}
                value={formik.values.password}
                onChange={formik.handleChange}
                helperText={formik.touched.password && formik.errors.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <p className={styles.error}>{formik.errors.password}</p>
              ) : (
                ""
              )}
              {/* {address === undefined ? (
                <Button onClick={handleOpen}>Connect wallet</Button>
              ) : (
                <Button>
                  {" "}
                  {address.slice(0, 5) + "..." + address.slice(37, 42)}{" "}
                </Button>
              )}*/}
              <Button type="submit" disabled={btnState}>
                Create Account
              </Button>
              <div className="col-lg-12">
                <a href="/login" className="float-start">
                  Already a Member ? Log in
                </a>
              </div>
            </form>
          </div>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Wallet for connection
          </Typography>
          {/* <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={trustWalletConnection}
          >
            <img src={trustWalletImg} />
            Trust Wallet
          </Typography> */}
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={coinbaseWalletConnection}
          >
            <img src={coinBaseImg} />
            Coinbase Wallet
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={metamaskConnection}
          >
            <img src={metaMaskImg} /> Metamask
          </Typography>
          {/* <Button onClick={walletConnect} className={`mt-3 ${styles.connectedButon}`}>Connect to wallet</Button> */}
        </Box>
      </Modal>
    </div>
  );
};

export default Form;
