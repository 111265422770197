import React, { useState } from "react";
import styles from "./style.module.scss";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { contactRequest } from "../../Redux/Card/actions";
// import Contct from "../../Assets/contact.png"
import * as Yup from "yup";

export default function Contact({ img }) {
  const dispatch = useDispatch();
  const [btnState, setBtnState] = useState(false);
  const contactUserSchema = Yup.object().shape({
    email: Yup.string().trim()
      .email("Please enter valid email address")
      .required("Please enter email address"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: contactUserSchema,
    onSubmit: (values) => {
      setBtnState(true);
      dispatch(
        contactRequest({
          email: values.email,
          setBtnState: setBtnState,
          resetForm: formik.resetForm,
        })
      );
    },
  });
  return (
    <div className={`row mx-0 ${styles.mainForm}`}>
      <div className="col-lg-12 text-center my-5"><h2>Contact Us</h2></div>
      <div className={`col-lg-5 p-3 ${styles.formContact}`}>
        <h1 className="my-3">Got a Question or Comment?</h1>
        <p>
          For any enquires and feedback please contact us by sending us your contact email and we will get back to you as soon as possible. We would love to hear your feedback on how we can help improve our propositions.
        </p>
        <form onSubmit={formik.handleSubmit}>
          <input
            className={styles.input}
            name="email"
            placeholder="Enter Your email"
            value={formik.values.email}
            onChange={formik.handleChange}
            helperText={formik.touched.email && formik.errors.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className={styles.error}>{formik.errors.email}</p>
          ) : (
            ""
          )}
          <button type="submit" disabled={btnState}>Submit</button>
        </form>
      </div>
      <div className={`col-lg-7 ${styles.contactImg}`}>
        <img src={img} alt="" />
      </div>
    </div>
  );
}
