import React, { useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from "./Card"

import { getBuyerList } from "../../Redux/Card/actions"

//style
import styles from "./style.module.scss"
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function BasicTabs({ buyerListData, sellerListData }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      width: '100%'
    }}>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Cars for Sale" />
          <Tab label="Cars Wanted" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className='row'>
          {sellerListData?.slice(0, 8).map((item, index) => {
            return (
              <div className='col-lg-3 my-2' sx={{ border: "1px solid blue" }}>
                <Card data={item} index={index} />
              </div>
            )
          })}
          <div className="col-lg-12 text-center">
            {sellerListData?.length > 0 && (
              <Link to="/allListing?seller"><button className={`my-4 ${styles.button}`}>Show More</button></Link>
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className='row'>
          {buyerListData?.slice(0, 8).map((item, index) => {
            return (
              <div className='col-lg-3 my-2'>
                <Card data={item} index={index} />
              </div>
            )
          })}
          {buyerListData?.length > 0 && (
            <div className="col-lg-12 text-center">
              <Link to="/allListing?buyer"><button className={`my-4 ${styles.button}`}>Show More</button></Link>
            </div>
          )}
        </div>
      </TabPanel>
    </Box>
  );
}