import {
  LOGIN,
  SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FORGOT_USERNAME,
  PROFILE_DATA,
  GOOGLE_LOGIN,
  FACEBOOK_LOGIN,
} from "./const";
import { BASE_URL_API } from "../../config/const.js";
import axios from "axios";
import { toast } from "react-toastify";

let token = localStorage?.getItem("Token");

export const SignUpRequest = (payload) => async (dispatch) => {
  const { userName, password, email, fullName, walletAddress } = payload;
  let url = `${BASE_URL_API}auth/register`;
  let data = {
    // userName: userName,
    password: password,
    email: email,
    // walletAddress: walletAddress,
    firstName: fullName,
  };
  try {
    let response = await axios.post(url, data);
    dispatch({
      type: SIGN_UP,
      payload: response.data.user,
    });
    toast.success("user signup successfully");
    // setTimeout(() => {
      window.location.href = "/login";
    // }, 1500);
    payload.setBtnState(false);
    // payload.setLoader(false)
  } catch (err) {
    toast.error(err.response.data.message);
    payload.setBtnState(false);
  }
};

export const Login = (payload) => async (dispatch) => {
  const { email, password } = payload;
  let url = `${BASE_URL_API}auth/login`;
  let data = {
    email: email,
    password: password,
  };
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let response = await axios.post(url, data, axiosConfig);
    let Token = response.data.data.accessToken;
    dispatch({
      type: LOGIN,
      payload: response.data.data.user,
    });
    localStorage.setItem("Token", Token);
    if (response.data.data.user.admin) {
      localStorage.setItem("Admin", "True");
    }
    toast.success(response.data.data.status);
    payload.setBtnState(false);
    // payload.setLoader(false)
    // setTimeout(()=>{
    window.location.href="/";
    // }, 1500)
  } catch (err) {
    toast.error(err.response.data.data.message);
    payload.setBtnState(false);
    payload.setLoader(false)
  }
};

export const isLogin = () => {
  let token = localStorage.getItem("Token");
  if (token) {
    return true;
  }
  return false;
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/login";
  // window.location.reload();
};

export const forgotPassword = (payload) => async (dispatch) => {
  const { email } = payload;
  let data = {
    email: email,
  };
  let url = `${BASE_URL_API}auth/forgot-password`;
  try {
    let response = await axios.post(url, data);
    toast.success(response.data.data.message);
    payload.setBtnState(false);
  } catch (err) {
    toast.error(err.response.data.data.message);
    payload.setBtnState(false);
  }
};

export const forgotUsername = (payload) => async (dispatch) => {
  const { email } = payload;
  let data = {
    email: email,
  };
  let url = `${BASE_URL_API}auth/forgot-username`;
  try {
    let response = await axios.post(url, data);
    dispatch({
      type: FORGOT_USERNAME,
      payload: response.data,
    });
    toast.success(response.data.message);
    payload.setBtnState(false);
  } catch (err) {
    toast.error(err.response.data.message);
    payload.setBtnState(false);
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  const { newpassword, userId } = payload;
  let data = {
    password: newpassword,
    token: userId,
  };
  let url = `${BASE_URL_API}auth/reset-password`;
  try {
    let response = await axios.post(url, data);
    console.log(response, "response")
    toast.success(response.data.data.message);
    setTimeout(() => {
      window.location.href = "/login";
    }, 1500);
    payload.setBtnState(false);
  } catch (err) {
    toast.error(err.response.data.data.message);
    payload.setBtnState(false);
  }
};

export const userList = (payload) => async (dispatch) => {
  let url = `${BASE_URL_API}user/getProfile`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: PROFILE_DATA,
      payload: response.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.data.message);
  }
};

export const googleLogin = (payload) => async (dispatch) => {
  const { idToken } = payload;
  let url = `${BASE_URL_API}auth/googleLogin`;
  let data = {
    idToken: idToken
  };
  try {
    let response = await axios.post(url, data);
    let Token = response.data.token;
    dispatch({
      type: GOOGLE_LOGIN,
      payload: response.data.user,
    });
    localStorage.setItem("Token", Token);
    toast.success(response.data.message);
    setTimeout(() => {
      window.location.href = "/";
    }, 1500)
  } catch (err) {
    toast.error(err.response.data.data.message);
  }
};

export const facebookLogin = (payload) => async (dispatch) => {
  const { facebookId, userName } = payload;
  let url = `${BASE_URL_API}auth/facebookLogin`;
  let data = {
    facebookId: facebookId,
    userName: userName,
  };
  try {
    let response = await axios.post(url, data);
    let Token = response.data.token;
    dispatch({
      type: FACEBOOK_LOGIN,
      payload: response.data.user,
    });
    localStorage.setItem("Token", Token);
    toast.success(response.data.message);
    setTimeout(() => {
      window.location.href = "/";
    }, 1500)
  } catch (err) {
    toast.error(err.response.data.data.message);
  }
};