import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../Assets/footerLogo.png";
import styles from "./style.module.scss";
import facebook from "../../Assets/Frame.png";
import insta from "../../Assets/Frame (1).png";
import twitter from "../../Assets/Frame (2).png";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default function Footer() {
  const dispatch = useDispatch();

  return (
    <div className={`row mx-0 ${styles.Footer}`}>
      <div className={`col-lg-2 ${styles.ListFirst}`}>
        <img src={Logo} alt="" />
      </div>
      <div className={`col-lg-2 ${styles.List}`}>
        <h2>Community</h2>
        <ul className="my-3 p-0">
          <Link to="/aboutUs">
            <li>About</li>
          </Link>
        </ul>
      </div>
      {/* <div className={`col-lg-2 ${styles.List}`}>
        <h2>Exchange</h2>
        <ul className="my-3 p-0">
          <Link to="/bridge">
            <li>Buy ROSH</li>
          </Link>
          <a href="https://swap.roshini.io" target="_blank">
            <li>DEX</li>
          </a>
          <Link to="https://swap.roshini.io">
            <li>DEX</li>
          </Link>
        </ul>
      </div> */}
      <div className={`col-lg-3 ${styles.List}`}>
        <h2>Resources</h2>
        <ul className="my-3 p-0">
          <Link to="/TermsAndCondition">
            {" "}
            <li>Terms & Conditions</li>{" "}
          </Link>
        </ul>
      </div>
      <div className={`col-lg-2 ${styles.List}`}>
        <h2>Explore</h2>
        <ul className="my-3 p-0">
          <Link to="/Faq">
            {" "}
            <li>FAQs</li>
          </Link>
        </ul>
      </div>
      <div className={`col-lg-3 ${styles.List}`}>
        <h2>Social Links</h2>
        <ul className="d-flex justify-content-evenly my-3">
          <li>
            <img src={facebook} alt="" />
          </li>
          <li>
            <img src={twitter} alt="" />
          </li>
          <li>
            <img src={insta} alt="" />
          </li>
        </ul>
      </div>
    </div>
  );
}
