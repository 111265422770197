import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from "../CarListing/Card"
import noData from "../../Assets/noData.png";
import { getLogedBuyerList, getLogedSellerList, getPuchaseList} from "../../Redux/Card/actions"
import MyPurchase from "../Listing/buyedListing"

//styles imort
import styles from "./style.module.scss";

export default function Dashboard() {
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = useState(true);
  const [selletData, setSellerData] = useState([]);
  const [purchaseData, setPuchaseData] = useState([]);
  const [buyData, setbuyData] = useState([]);
  const userData = useSelector((state) => state.authReducer?.login);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  // const userData = useSelector((state) => state.authReducer?.login);
  const dispatch = useDispatch();
  const buyerLogedListData = useSelector((state) => state?.CardReducer?.buyerLogedList?.allAds);
  const sellerLogedListData = useSelector((state) => state?.CardReducer?.sellerLogedList?.allAds);
  // const purchaseListData = useSelector((state) => state?.CardReducer?.myPurchase?.allAds);
  useEffect(() => {
    if (buyerLogedListData !== undefined) {
      setbuyData(buyerLogedListData)
    }
    if (sellerLogedListData !== undefined) {
      setSellerData(sellerLogedListData)
    }
    // if (purchaseListData !== undefined) {
    //   setPuchaseData(purchaseListData)
    // }
  }, [buyerLogedListData, sellerLogedListData])

  useEffect(() => {
    dispatch(getLogedBuyerList({ setLoader: setLoader }))
    dispatch(getLogedSellerList({ setLoader: setLoader }))
    // dispatch(getPuchaseList({ setLoader: setLoader }))
      window.scrollTo(0, 0);
  }, [])
  return (
    <div className={`cotnainer ${styles.mainDashboard}`}>
      <div className={`col-12 m-3 text-start ${styles.head}`}>
        <h3>Users Car Listing</h3>
        <span>Users All Buying And Create Listing Data</span>
      </div>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Sell Car" />
            <Tab label="Buy Car" />
            <Tab label="My purchase" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className='row mx-0'>
            {selletData?.length > 0 ?
              (
                selletData?.map((item, index) => {
                  return (
                    <div className='col-lg-4 my-4'>
                      <Card data={item} index={index} />
                    </div>
                  )
                })
              )
              : (
                <div style={{ textAlign: "center" }}>
                  <img src={noData} alt="" width="100px" className="my-3"/>
                </div>
              )}

          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className='row mx-0'>
            {buyData?.length > 0 ?
              (
                buyData?.map((item, index) => {
                  return (
                    <div className='col-lg-4 my-4'>
                      <Card data={item} index={index} />
                    </div>
                  )
                })
              )
              : (
                <div style={{ textAlign: "center" }}>
                  <img src={noData} alt="" width="100px" className="my-3"/>
                </div>
              )}

          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className='row mx-0'>
           <MyPurchase />
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}