import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Button } from '@mui/material';
import carMamaPublicMint from "../../contracts/CarMammaPublicMint.json";
import carMamaNFT from "../../contracts/CarMammaVehicleRegistration.json";
import carMamaMarketPlace from "../../contracts/CarMammasMarketplace.json";
// import Modal from '@mui/material/Modal';
import { create } from "ipfs-http-client";
import { Buffer } from "buffer";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { RMIUploader } from "react-multiple-image-uploader";
import { Countries, States, Cities } from "countries-states-cities-service";
import Spinner from "../../Components/spinner/spinner";
//styles imort
import styles from "./style.module.scss";
import { Country, State, City } from "country-state-city";
import {
  postSellerForm,
  postBuyForm,
  getCarData,
} from "../../Redux/Card/actions";
import hondaLogo from "../../Assets/honda.png";
import ToyotaLogo from "../../Assets/toyota.png";
import mazdaLogo from "../../Assets/mazda.png";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { asciiCodes } from "ascii-codes";

import { signersWallet, addresses, provider } from "../../walletConnect";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

let features = [];

export default function BuyCard() {
  const dispatch = useDispatch();
  const [btnState, setBtnState] = useState(false);
  let check = window.location.search.split("?")[1];
  let cardId = window.location.search.split("?")[2];
  const [cityArea, setCityArea] = useState(null);
  const [extension, setExtension] = useState();
  const [value, setValue] = useState("");
  const [platformFee, setPlatformFee] = useState(0);
  const [showDefaultCategory, setShowDefaultCategory] = useState(false);
  const handleClosesCategory = () => setShowDefaultCategory(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [makeYear, setMakeYear] = useState("");
  const [modalYear, setModalYear] = useState("");
  const [models, setModels] = useState();
  const [region, setRegion] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [key, setkey] = useState(false);
  const [VersionYear, setVersionYear] = useState("");
  // const [signerData, setsignerData] = useState();
  const [Abs, setAbs] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [filteredModalArray, setFilteredModalArray] = useState();

  const [image1, setImage1] = useState();
  const [balance, setBalance] = useState();
  const [loader, setLoader] = useState(false);

  const getMakeData = useSelector((state) => state?.CardReducer?.getCarData);
  const projectId = "2LgE2EbPboT9Vod4yuweCGYYe8E"; // <---------- your Infura Project ID
  const projectSecret = "f3a2bbc74cf9c98d8aaaedd20dc337ac"; // <---------- your Infura Secret
  const auth =
    "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

  const client = create({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
    headers: {
      authorization: auth,
    },
  });
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files);
      setImage1(e.target.files[0]);
    }
  };
  const [airBag, setAirBag] = useState(false);
  const sellSchema = Yup.object().shape({
    registeredIn: Yup.string().trim().required("Please enter description"),
    Mileage: Yup.string().required("Please enter mileage"),
    Price: Yup.string().required("Price is required"),
    Description: Yup.string().trim().required("Please enter description"),
    carNumber: Yup.string().required("Please enter car number"),
    country: Yup.string().trim().required("Please select country"),
  });
  const buySchema = Yup.object().shape({
    // registeredIn: Yup.string().trim().required("Please enter description"),
    // Mileage: Yup.string().required("Please enter mileage"),
    // Price: Yup.string()
    //   .required("Price is required"),
    // Description: Yup.string().trim().required("Please enter description"),
    // carNumber: Yup.string().required("Please enter car number"),
  });
  const [selectedArray, setSelectedArray] = useState("");

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked && !selectedCheckboxes.includes(checkboxValue)) {
      setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((value) => value !== checkboxValue)
      );
    }
  };
  const registerCar = async (values) => {
    let carNum = values.carNumber;
    if (check === "sellCar") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signers = provider.getSigner();
      const carMammasNFT = new ethers.Contract(
        carMamaPublicMint.address,
        carMamaPublicMint.abi,
        signers
      );
      const nft = new ethers.Contract(
        carMamaNFT.address,
        carMamaNFT.abi,
        signers
      );
      const carMammasMarketplace = new ethers.Contract(
        carMamaMarketPlace.address,
        carMamaMarketPlace.abi,
        signers
      );
      if (image1 !== undefined) {
        try {
          let tokenUri = "";
          try {
            const result = await client.add(image1);
            const imageUri = `https://carmammas.infura-ipfs.io/ipfs/${result.path}`;
            const attributes = [
              {
                trait_type: "Price",
                value: values.Price,
              },
              {
                trait_type: "Contact Number",
                value: value,
              },
              {
                trait_type: "Registered In",
                value: values.registeredIn,
              },
              {
                trait_type: "Make",
                value: values.makeYear,
              },
              {
                trait_type: "Model",
                value: values.modelYear,
              },
              {
                trait_type: "Varient",
                value: values.VersionYear,
              },
              {
                trait_type: "Exterior Color",
                value: values.extension,
              },
              {
                trait_type: "Mileage",
                value: values.Mileage,
              },

              {
                trait_type: "Features",
                value: selectedCheckboxes,
              },
              {
                trait_type: "Car Number",
                value: carNum,
              },
            ];
            const metadata = await client.add(
              JSON.stringify({
                name:
                  values.makeYear +
                  " " +
                  values.modelYear +
                  " " +
                  values.VersionYear,
                image: imageUri,
                Description: values.Description,
                attributes,
              })
            );
            tokenUri = `https://carmammas.infura-ipfs.io/ipfs/${metadata.path}`;
            if (tokenUri === undefined) {
              toast.error("Metadata Uploading Failed");
            }
          } catch (error) {
            console.log("🚀 ~ registerCar ~ error:", error);
          }

          try {
            let tx = await (await carMammasNFT.registerCar(tokenUri)).wait();
            console.log(tx.events)
            const tokenId = parseInt(tx.events[0].topics[3]);
            await (
              await nft.setApprovalForAll(carMammasMarketplace.address, true)
            ).wait();
            const price = ethers.utils.parseEther(values.Price.toString());
            const platformFees = ethers.utils.parseEther(platformFee.toString());
            await (
              await carMammasMarketplace.listCar(tokenId, price, {
                gasLimit: 500000,
              })
            ).wait();

            dispatch(
              postSellerForm({
                Mileage: values.Mileage,
                Price: values.Price,
                PlatformFee: platformFee,
                setLoader: setLoader,
                Description: values.Description,
                contactNumber: value ? value : null,
                exteriorColor: values.color,
                registeredIn: values.registeredIn,
                city: values.city,
                image: selectedImage ? selectedImage : "",
                verient: VersionYear?.value,
                setBtnState: setBtnState,
                make: values.makeYear,
                model: values.modelYear,
                carNumber: carNum,
                tokenId: tokenId,
                carAdId: cardId ? cardId : null,
                features: selectedCheckboxes,
                isProposal: cardId && cardId !== undefined ? true : false,
              })
            );
          } catch (error) {
            setLoader(false);
            toast.error(error.reason);
          }
        } catch (error) {
          setLoader(false);
        } finally {
          // setLoader(false);
        }
      }
    } else {
      dispatch(
        postBuyForm({
          Mileage: values.Mileage ? values.Mileage : null,
          Price: values.Price ? values.Price : null,
          Description: values.Description ? values.Description : null,
          contactNumber: value ? value : null,
          setLoader: setLoader,
          exteriorColor: values.color,
          image:
            values.makeYear === "Honda"
              ? hondaLogo
              : values.makeYear === "Toyota"
              ? ToyotaLogo
              : values.makeYear === "Mazda"
              ? mazdaLogo
              : "",
          registeredIn: values.registeredIn ? values.registeredIn : null,
          verient: VersionYear?.value,
          carNumber: carNum,
          make: values.makeYear,
          features: selectedCheckboxes,
          model: values.modelYear,
        })
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      Mileage: 1,
      Price: 1,
      Description: "",
      Number: "",
      Telephone: "",
      city: "",
      cityArea: "",
      registeredIn: "",
      country: "",
      color: "",
      selectedImage: "",
      makeYear: "",
      modalYear: "",
      VersionYear: "",
      details: "",
      carNumber: "",
      features: [""],
    },
    enableReinitialize: true,
    validationSchema: check === "sellCar" ? sellSchema : buySchema,
    onSubmit: (values) => {
      if (value && isValidPhoneNumber(value)) {
        if (check === "sellCar") {
          if (signersWallet !== undefined && addresses !== undefined) {
            if (!selectedImage) {
              toast.error("Please select image");
            } else {
              setLoader(true);
              registerCar(values);
            }
          } else {
            setLoader(false);
            toast.error("please connect with wallet first");
          }
        } else {
          registerCar(values);
        }
      }
    },
  });
  const cityAreaArray = [
    { value: "Lahore", label: "Lahore" },
    { value: "karachi", label: "karachi" },
    { value: "Peshawar", label: "Peshawar" },
  ];
  const ExtensionArray = [
    { value: "Black", label: "Black" },
    { value: "Silver", label: "Silver" },
    { value: "White", label: "White" },
  ];
  const MakeArray = [
    { value: "Toyota", label: "Toyota" },
    { value: "Honda", label: "Honda" },
    { value: "Mazda", label: "Mazda" },
    { value: "Ford", label: "Ford" },
    { value: "Chevrolet", label: "Chevrolet" },
    { value: "Nissan", label: "Nissan" },
    { value: "Hyundai", label: "Hyundai" },
    { value: "Kia", label: "Kia" },
    { value: "Volkswagen", label: "Volkswagen" },
    { value: "Subaru", label: "Subaru" },
  ];

  const ModalArray = [
    { value: "Corolla", label: "Toyota Corolla Altis X 1.8" },
    { value: "Camry", label: "Toyota Camry" },
    { value: "LandCruiser", label: "Toyota LandCruiser" },
    { value: "RAV4", label: "Toyota RAV4" },
    { value: "Hilux", label: "Toyota Hilux" },
    { value: "City", label: "Honda City" },
    { value: "Civic", label: "Honda Civic" },
    { value: "Accord", label: "Honda Accord" },
    { value: "Pilot", label: "Honda Pilot" },
    { value: "CR-V", label: "Honda CR-V" },
    { value: "Alto", label: "Suzuki Alto" },
    { value: "Swift", label: "Suzuki Swift" },
    { value: "Vitara", label: "Suzuki Vitara" },
    { value: "Ertiga", label: "Suzuki Ertiga" },
    { value: "Mazda3", label: "Mazda Mazda3" },
    { value: "Mazda6", label: "Mazda Mazda6" },
    { value: "CX-5", label: "Mazda CX-5" },
    { value: "CX-9", label: "Mazda CX-9" },
    { value: "MX-5", label: "Mazda MX-5" },
    { value: "Fusion", label: "Ford Fusion" },
    { value: "Mustang", label: "Ford Mustang" },
    { value: "Ranger", label: "Ford Ranger" },
    { value: "Explorer", label: "Ford Explorer" },
    { value: "Focus", label: "Ford Focus" },
    { value: "Camaro", label: "Chevrolet Camaro" },
    { value: "Silverado", label: "Chevrolet Silverado" },
    { value: "Cruze", label: "Chevrolet Cruze" },
    { value: "Trax", label: "Chevrolet Trax" },
    { value: "Equinox", label: "Chevrolet Equinox" },
    { value: "Altima", label: "Nissan Altima" },
    { value: "Sentra", label: "Nissan Sentra" },
    { value: "GT-R", label: "Nissan GT-R" },
    { value: "X-Trail", label: "Nissan X-Trail" },
    { value: "Qashqai", label: "Nissan Qashqai" },
    { value: "Elantra", label: "Hyundai Elantra" },
    { value: "Sonata", label: "Hyundai Sonata" },
    { value: "Santa Fe", label: "Hyundai Santa Fe" },
    { value: "Tucson", label: "Hyundai Tucson" },
    { value: "Kona", label: "Hyundai Kona" },
    { value: "Rio", label: "Kia Rio" },
    { value: "Optima", label: "Kia Optima" },
    { value: "Sorento", label: "Kia Sorento" },
    { value: "Sportage", label: "Kia Sportage" },
    { value: "Soul", label: "Kia Soul" },
    { value: "Golf", label: "Volkswagen Golf" },
    { value: "Jetta", label: "Volkswagen Jetta" },
    { value: "Passat", label: "Volkswagen Passat" },
  ];

  const getModels = (selectedMake) => {
    const filteredModal = ModalArray.filter((car) => {
      return car.label.includes(selectedMake);
    });
    setFilteredModalArray(filteredModal);
  };

  const RegionArray = [
    { value: "PK", label: "PK" },
    { value: "GB", label: "UK" },
  ];
  const VersionArray = [
    { value: "Altis X 1.8", label: "Altis X 1.8" },
    { value: "1.2L CVT", label: "1.2L CVT" },
    { value: "VXR", label: "VXR" },
  ];

  const VersionYears = [
    // Loop through each year from 1990 to 2024
    ...Array(2024 - 1990 + 1)
      .fill()
      .map((_, index) => ({
        value: (1990 + index).toString(),
        label: (1990 + index).toString(),
      })),
  ];

  const allDataCity = Cities.getCities({
    filters: {
      country_code: selectedState?.countryCode,
      state_code: selectedState?.isoCode,
    },
  });

  function getModel() {
    setModels();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loader]);

  // useEffect(() => {
  //   dispatch(
  //     getCarData({
  //       makeYear,
  //       VersionYear,
  //       modalYear,
  //     })
  //   );
  // }, [makeYear, VersionYear, modalYear]);
  return (
    <div className={`container ${styles.mainForm}`}>
      {loader ? (
        <>
          <Spinner />
          {check === "sellCar" && (
            <h1 className="text-center">
              Blockchain is being called! Please wait.
            </h1>
          )}
        </>
      ) : (
        <>
          <form onSubmit={formik.handleSubmit} className={styles.Form}>
            <div className="row mx-0">
              <Col md={12} className="mb-3">
                <h1>{check === "sellCar" ? "Sell-" : "Buy-"}Car</h1>
                <span>(All fields marked with * are mandatory)</span>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>Select Country*</Form.Label>
                  <Select
                    value={region}
                    // onChange={setModalYear}
                    options={RegionArray}
                    onChange={(item) => {
                      formik.setFieldValue("country", item.value);
                      setRegion(item);
                      setSelectedState("");
                    }}
                  />
                  {formik?.touched?.country && !formik?.values?.country ? (
                    <div style={{ color: "red" }}>Please select country</div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    Registered In{check === "sellCar" ? "*" : ""}
                  </Form.Label>
                  <Select
                    options={State?.getStatesOfCountry(region?.value)}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedState}
                    onChange={(item) => {
                      formik.setFieldValue("registeredIn", item.name);
                      setSelectedState(item);
                    }}
                  />
                  {check === "sellCar" && (
                    <>
                      {formik?.touched?.registeredIn &&
                      !formik?.values?.registeredIn ? (
                        <div style={{ color: "red" }}>
                          Please select registered in
                        </div>
                      ) : null}
                    </>
                  )}
                </Form.Group>
              </Col>
              {region?.value === "PK" && (
                <Col md={4} className="mb-3">
                  <Form.Group>
                    <Form.Label>City*</Form.Label>
                    <Select
                      options={allDataCity}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={selectedCity}
                      onChange={(item) => {
                        formik.setFieldValue("city", item.name);
                        setSelectedCity(item);
                      }}
                    />
                    {check === "sellCar" && (
                      <>
                        {formik?.touched?.city && !formik?.values?.city ? (
                          <div style={{ color: "red" }}>Please select city</div>
                        ) : null}
                      </>
                    )}
                  </Form.Group>
                </Col>
              )}
              {/* <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>City Area*</Form.Label>
                  <Select
                    value={cityArea}
                    // onChange={setCityArea}
                    options={cityAreaArray}
                    onChange={(item) => {
                      formik.setFieldValue("cityArea", item.value);
                      setCityArea(item);
                    }}
                  />
                  {formik?.touched?.cityArea && !formik?.values?.cityArea ? (
                    <div style={{ color: "red" }}>Please select city area</div>
                  ) : null}
                </Form.Group>
              </Col> */}
              <Col md={4} className="mb-3">
                <Form.Group id="Car Info">
                  <Form.Label>Car Information*</Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Make/Model/Varient"
                    value={
                      selectedArray !== "undefined/undefined/undefined"
                        ? selectedArray
                        : ""
                    }
                    name="Car Info"
                    label="Make/Model/Varient"
                    // onChange={(e) => {
                    //     formik.setFieldValue("Mileage", e.target.value);
                    // }}
                    onClick={() => setShowDefaultCategory(true)}
                    className="text-underline"
                  />
                  {/* {formik?.touched?.Mileage && formik?.error?.Mileage ? (
                    <div style={{ color: "red" }}>{formik?.error?.Mileage}</div>
                  ) : null} */}
                </Form.Group>
              </Col>

              <Col md={4} className="mb-3">
                <Form.Group id="Car Info">
                  <Form.Label>
                    Exterior Color{check === "sellCar" ? "*" : ""}
                  </Form.Label>
                  <Form.Control
                    placeholder="Color"
                    value={formik.values.color}
                    name="color"
                    label="Exterior Color"
                    onChange={(e) => {
                      formik.setFieldValue("color", e.target.value);
                    }}
                  />
                  {check === "sellCar" && (
                    <>
                      {formik?.touched?.color && !formik?.values?.color ? (
                        <div style={{ color: "red" }}>
                          Please select exterior color
                        </div>
                      ) : null}
                    </>
                  )}
                </Form.Group>
              </Col>

              {/* <Col md={4} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    Exterior Color{check === "sellCar" ? "*" : ""}
                  </Form.Label>
                  <Select
                    value={extension}
                    // onChange={setExtension}
                    options={getMakeData?.colorArray}
                    onChange={(item) => {
                      formik.setFieldValue("extension", item.value);
                      setExtension(item);
                    }}
                  />
                  {check === "sellCar" && (
                    <>
                      {formik?.touched?.extension &&
                      !formik?.values?.extension ? (
                        <div style={{ color: "red" }}>
                          Please select exterior color
                        </div>
                      ) : null}
                    </>
                  )}
                </Form.Group>
              </Col> */}
              <Col md={4} className="mb-3">
                <Form.Group id="Car Info">
                  <Form.Label>
                    Mileage{check === "sellCar" ? "*" : ""} (
                    {region?.value === "PK" ? "KM" : "Miles"})
                  </Form.Label>
                  <Form.Control
                    // required
                    type="number"
                    // min="1"
                    placeholder="Mileage"
                    value={formik.values.Mileage}
                    name="Car Info"
                    label="Mileage"
                    onChange={(e) => {
                      formik.setFieldValue("Mileage", e.target.value);
                    }}
                  />
                  {check === "sellCar" && (
                    <>
                      {formik?.touched?.Mileage && !formik?.values?.Mileage ? (
                        <div style={{ color: "red" }}>Please enter mileage</div>
                      ) : (
                        <>
                          {formik?.values?.Mileage <= 0 && (
                            <div style={{ color: "red" }}>
                              Values must be greater than 0
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="Car Info">
                  <Form.Label>
                    Price{check === "sellCar" ? "*" : ""} (USDT) Listing Fee:{" "}
                    {platformFee.toFixed(3)} ROSH
                  </Form.Label>
                  <Form.Control
                    // required
                    type="number"
                    // min="1"
                    placeholder="Price"
                    value={formik.values.Price}
                    name="Car Info"
                    label="Price"
                    onChange={(e) => {
                      formik.setFieldValue("Price", e.target.value);
                      setPlatformFee(
                        ((parseInt(e.target.value) / 100) * 2.5) / 0.015
                      );
                    }}
                  />
                  {check === "sellCar" && (
                    <>
                      {formik?.touched?.Price && !formik?.values?.Price ? (
                        <div style={{ color: "red" }}>Please enter price</div>
                      ) : (
                        <>
                          {formik?.values?.Price <= 0 && (
                            <div style={{ color: "red" }}>
                              Values must be greater than 0
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="Car Number">
                  <Form.Label>
                    Registration Number{check === "sellCar" ? "*" : ""}
                  </Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Car Number"
                    value={formik.values.carNumber}
                    name="Car number"
                    label="carNumber"
                    onChange={(e) => {
                      formik.setFieldValue("carNumber", e.target.value);
                    }}
                  />
                  {check === "sellCar" && (
                    <>
                      {formik?.touched?.carNumber &&
                      !formik?.values?.carNumber ? (
                        <div style={{ color: "red" }}>
                          Please enter registration number
                        </div>
                      ) : null}
                    </>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Group id="description">
                  <Form.Label>
                    Description{check === "sellCar" ? "*" : ""}
                  </Form.Label>
                  <Form.Control
                    fullWidth
                    type="text"
                    as="textarea"
                    rows="3"
                    placeholder="Description"
                    value={formik.values.Description}
                    name="description"
                    label="description"
                    onChange={(e) => {
                      formik.setFieldValue("Description", e.target.value);
                    }}
                  />
                  {check === "sellCar" && (
                    <>
                      {formik.touched.Description &&
                      formik.errors.Description ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.Description}
                        </div>
                      ) : null}
                    </>
                  )}
                </Form.Group>
              </Col>
            </div>
            {/* </Card> */}
            {/* <Card sx={{ borderRadius: 8, marginTop: 5 }}>
          <CardHeader title="Features" /> */}
            <div className="row mx-0">
              <Col md={12}>
                <h1 style={{ marginBottom: -10 }}>Features</h1>
              </Col>
              <Col md={2} className="mb-3">
                <Form.Group id="ABS">
                  <Form.Label></Form.Label>
                  <fieldset className="d-flex checkbox p-2">
                    <Form.Check
                      // defaultChecked
                      type="checkbox"
                      label="ABS"
                      name="ABS"
                      checked={Abs}
                      value="ABS"
                      // value={ABS}
                      className="radio1"
                      onChange={(event) => {
                        handleCheckboxChange(event);
                        setAbs(event.target.checked);
                      }}
                    />
                  </fieldset>
                  <fieldset className="d-flex checkbox p-2">
                    <Form.Check
                      // defaultChecked
                      type="checkbox"
                      label="Airbag"
                      name="airBag"
                      checked={airBag}
                      // value={airBag}
                      value="Airbag"
                      className="radio1"
                      onChange={(event) => {
                        handleCheckboxChange(event);
                        setAirBag(event.target.checked);
                      }}
                    />
                  </fieldset>
                </Form.Group>
              </Col>
            </div>
            {/* </Card> */}
            {check === "sellCar" && (
              // <Card sx={{ borderRadius: 8, marginTop: 5 }}>
              //   <CardHeader title="Upload Images" />
              <div className="row mx-0">
                <Col md={12} className="mb-3">
                  <h1>Upload Images</h1>
                  {/* <span>You can upload maximun 5 imges</span> */}
                </Col>
                <Col md={12} className="mb-3">
                  <Form.Group id="Car Info">
                    {/* <Form.Label>Uplaod Images</Form.Label> */}
                    <Form.Control
                      multiple
                      accept="image/*"
                      type="file"
                      id="file"
                      name="file"
                      // onChange={onSelectFile}
                      // onChange={(item) => {
                      //     formik.setFieldValue("selectedFile", item.value);
                      //     setSelectedFile(item);
                      // }}
                      onChange={(e) => imageChange(e)}
                      // className="d-none"
                      // ref={inputEl}
                    />
                  </Form.Group>
                </Col>
              </div>
              // {/* </Card> */}
            )}
            {/* <Card sx={{ borderRadius: 8, marginTop: 5 }}>
          <CardHeader title="Contact Information" /> */}
            <div className="row mx-0">
              <Col md={12} className="mb-2">
                <h1>Contact Number</h1>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="Car Info">
                  <Form.Label>Mobile Number*</Form.Label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={value}
                    onChange={setValue}
                  />
                  {value ? (
                    isValidPhoneNumber(value) ? undefined : (
                      <div style={{ color: "red" }}>Invalid phone number</div>
                    )
                  ) : (
                    <>
                      {formik.touched?.Number && (
                        <div style={{ color: "red" }}>
                          Please enter mobile number
                        </div>
                      )}
                    </>
                  )}
                  {/* {!value && (
                     <div style={{ color: "red" }}>
                     Please enter mobile number
                   </div>
                   )} */}
                  {/* <Form.Control
                  // required
                  type="number"
                  min="1"
                  placeholder="Mobile Number"
                  value={formik.values.Number}
                  name="Car Info"
                  label="Mileage"
                  onChange={(e) => {
                    formik.setFieldValue("Number", e.target.value);
                  }}
                />
                {formik.touched.Number && formik.errors.Number ? (
                  <div style={{ color: "red" }}>{formik.errors.Number}</div>
                ) : null} */}
                </Form.Group>
              </Col>
            </div>
            <div className="col-lg-12">
              <Button type="submit" disabled={btnState}>
                Submit & Continue
              </Button>
            </div>
            {/* </Card> */}
          </form>
        </>
      )}
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefaultCategory}
        onHide={() => {
          setShowDefaultCategory(false);
          setMakeYear("");
          setModalYear("");
          setVersionYear("");
        }}
      >
        <Modal.Header>
          <Modal.Title className="h5">Add Car Details</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => {
              setShowDefaultCategory(false);
              setMakeYear("");
              setModalYear("");
              setVersionYear("");
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Make</Form.Label>
              <Select
                value={makeYear}
                // onChange={setMakeYear}
                options={MakeArray}
                onChange={(item) => {
                  formik.setFieldValue("makeYear", item.value);
                  setMakeYear(item);
                  getModels(item.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Year</Form.Label>
              <Select
                value={VersionYear}
                // onChange={setVersionYear}
                options={VersionYears}
                onChange={(item) => {
                  formik.setFieldValue("VersionYear", item.value);
                  setVersionYear(item);
                  // Call the additional function here
                  getModel();
                }}
              />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Model</Form.Label>
              <Select
                value={modalYear}
                options={filteredModalArray}
                onChange={(item) => {
                  formik.setFieldValue("modelYear", item.value);
                  setModalYear(item);
                }}
              />
            </Form.Group>
          </Col>

          <div className="col-lg-12 pb-5">
            <Button
              type="submit"
              onClick={() => {
                if (makeYear && modalYear) {
                  setShowDefaultCategory(false);
                  setSelectedArray(
                    makeYear?.value +
                      "/" +
                      VersionYear?.value +
                      "/" +
                      modalYear?.value
                  );
                } else {
                  toast.error("Please select all fields");
                }
              }}
              // className="text-underline"
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
