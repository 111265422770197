import {LOGIN,SIGN_UP, USER_LIST,GOOGLE_LOGIN,FACEBOOK_LOGIN, PROFILE_DATA} from "./const"
const initialState = {
    login: "",
    signUp:"",
    userList:[],
    profileData:"",
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          login: action.payload,
        };
        case GOOGLE_LOGIN:
          return {
            ...state,
            login: action.payload,
          };
          case FACEBOOK_LOGIN:
          return {
            ...state,
            login: action.payload,
          };
        case SIGN_UP:
        return {
          ...state,
          signUp: action.payload,
        };
        case USER_LIST:
          return {
            ...state,
            userList: action.payload,
          };
          case PROFILE_DATA:
          return {
            ...state,
            profileData: action.payload,
          };
      default:
        return state;
    }
  };