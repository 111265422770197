export const CONTACT_FORM = "CONTACT_FORM";
export const CITY_LIST = "CITY_LIST";
export const SINGLE_CITY_DATA = "SINGLE_CITY_DATA";
export const SINGLE_CAR_DATA = "SINGLE_CAR_DATA";
export const BUYER_LIST = "BUYER_LIST";
export const SELLER_LIST = "SELLER_LIST";
export const BUYER_LOGED_LIST = "BUYER_LOGED_LIST";
export const SELLER_LOGED_LIST = "SELLER_LOGED_LIST";
export const BUY_CAR_FORM = "BUY_CAR_FORM";
export const SELLER_CAR_FORM= "SELLER_CAR_FORM";
export const USER_LIST = "USER_LIST";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const MY_PURCHASE = "MY_PURCHASE";
export const PROSAL_DATA = "PROSAL_DATA";
export const GET_CAR_DATA = "GET_CAR_DATA";
