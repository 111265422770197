import {PLAN_CHARGES} from "./const"
const initialState = {
    chargeList: "",
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case PLAN_CHARGES:
        return {
          chargeList: action.payload,
        };
      default:
        return state;
    }
  };