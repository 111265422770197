import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./style.module.scss";
import Mainimage from "../../Assets/mainimage.png";
import Card from "../../Components/Card/index"
import ContactUs from "../../Components/ContactForm/index"
import CarListing from "../CarListing/index"
import { getCities, getBuyerList, getSellerList } from "../../Redux/Card/actions"
import { Link } from "react-router-dom";
import Spinner from "../../Components/spinner/spinner";
import Contct from "../../Assets/contact.png"

const HomePage = () => {
  const dispatch = useDispatch();
  const CityData = useSelector((state) => state?.CardReducer?.citiList?.AllSellerAds);
  const buyerListData = useSelector((state) => state?.CardReducer?.buyerList?.AllSellerAds);
  const sellerListData = useSelector((state) => state?.CardReducer?.sellerList?.AllSellerAds);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    dispatch(getCities({setLoader:setLoader}));
    dispatch(getBuyerList({setLoader:setLoader}));
    dispatch(getSellerList({setLoader:setLoader}));
  }, [])
  return (
    <>
      <div className={`container-fluid my-5 ${styles.mainContiner}`}>
        {/* {
          loader ? (
            <Spinner />
          ) : <> */}
            <div className={styles.imgSection}>
              <img src={Mainimage} alt="" />
            </div>
            <div className={styles.imgData}>
              <span >Welcome To Carmammas</span>
              <h2 className="my-4">BUY & SELL Cars Using Crypto Currencies</h2>
              <Link to="/allListing?buyer" className={`${styles.traingbuton}`}>
                <button className="my-2">Search</button>
              </Link>
            </div>
          {/* </> */}
        {/* } */}
      </div>
      <div className="container">
        {
          loader ? (
            <Spinner />
          ) : <>
            <div className="row">
              <div className={`col-lg-12 text-center my-5 ${styles.nearBy}`}>
                <h1>
                  <span>
                    Nearby Cars
                  </span>
                </h1>
                {/* <span>Discover best cars near where you live
                </span> */}
              </div>
              {CityData?.slice(0, 8).map((item, index) => {
                return (
                  <div className="col-lg-3 col-md-4 my-2">
                    <Card data={item} index={index} />
                  </div>
                )
              })}
               <div className="col-lg-12 text-center">
            {CityData?.length > 0 && (
              <Link to="/nearBy"><button className={`my-4 ${styles.button}`}>Show More</button></Link>
            )}
          </div>
            </div>
          </>
        }
        {/* car listing data */}
        {
          loader ? (
            <Spinner />
          ) : <>
            <div className="row">
              <div className={`col-lg-12 my-5 text-center ${styles.nearBy}`}>
                <h1>
                  <span>
                    Marketplace
                  </span>
                </h1>
                <span>Popular Cars recommended by carmammas for you
                </span>
              </div>
              <CarListing buyerListData={buyerListData} sellerListData={sellerListData} />

            </div>
          </>
        }
      </div>
      <ContactUs img={Contct}/>
    </>
  );
}

export default HomePage;