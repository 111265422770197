import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../CarListing/style.module.scss";
import Card from "../CarListing/Card";
import { getLogedSellerList } from "../../Redux/Card/actions"
import Spinner from "../../Components/spinner/spinner";
import Image from "../../Assets/noData.png";
const SellerListing = () => {
    const dispatch = useDispatch();
    const sellerListData = useSelector((state) => state?.CardReducer?.sellerLogedList?.allAds);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        dispatch(getLogedSellerList({ setLoader: setLoader }));
    }, [])
    return (
        <div className="container my-5">
            {
                loader ? (
                    <Spinner />
                ) :
                    <div className="row mt-5">
                        <div className={`col-lg-12 my-5 ${styles.nearBy}`}>
                            <h1>
                                <span>
                                    Featured places to stay
                                </span>
                            </h1>
                            <span>Popular places to stay that Chisfis recommends for you
                            </span>
                        </div>
                        {sellerListData?.length > 0 ?
                            (
                                sellerListData?.map((item, index) => {
                                    return (
                                        <>
                                            <Card data={item} index={index} />
                                        </>
                                    )
                                })
                            )
                            : (
                                <div className="text-center">
                                    <img src={Image} alt="" width="100px" />
                                </div>
                            )}
                    </div>
            }
        </div>
    );
}

export default SellerListing;