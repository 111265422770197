import React,{ useState, useEffect, useRef } from 'react'
import Contact from "../../Components/ContactForm/index";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import contactImg from "../../Assets/conact2.png"

import styles from "./style.module.scss";

export default function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return (
        <div className={`container ${styles.mainAboutus}`}>
            <div className={`my-10 text-center ${styles.cardText}`}>
                <Card sx={{ borderRadius: 3 }} >
                    <div className='row' style={{ paddingBottom: "15%" }}>
                        <div className='col-12 text-center'>
                            <h4 className='p-5'>About Us</h4>
                        </div>
                        <div className={`col-lg-5 mb-9`}>
                            <p className="text-start mx-5">We aim to give our members access to a real-world product to convert their crypto assets to. By joining our community platform you will be able to purchase vehicles and register their ownership. Making your purchase enforceable under the local jurisdiction. The platform will also allow you to make cross-border purchases of vehicles in the United Kingdom and the Arab Emirates. This will be facilitated through e-commerce integration of the platform, NFT’s and smart contracts. The interface is user friendly and continues to evolve with user feedback.</p>
                        </div>
                        <div className='col-lg-1'>
                        <div className={` ${styles.box}`}>
                                    <div className='' style={{ height: "48px", width: "48px" }}>
                                    </div>
                                </div>
                                <div className={`${styles.boxs}`}>
                                    <div className='' style={{ height: "48px", width: "48px" }}>
                                    </div>
                                </div>
                        </div>
                        <div className={`col-lg-5`}>
                            <div>
                                <p className="text-start" >
                                In the future, we are aiming to provide a complete service from onboarding, vetting, servicing, and delivery of your purchased vehicle and offboarding of your crypto assets. All under one roof.</p>
                            </div>
                            <div className="text-start">
                                <h5 className="text-start">
                                We aim to add additional services, such as:</h5>
                                <p>Condition reports which will contain the mechanical and cosmetic condition of the vehicle</p>
                                <p>Vehicle registration services for buyers and sellers</p>
                                <p>Currency conversion from one crypto to another</p>
                                <p>Delivery services for your purchase or sale</p>
                            </div>
                        </div>


                    </div>
                </Card>
            </div>

            <Contact img={contactImg} />

        </div>
    )
}