import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "../../Pages/CarListing/Card"
import { getUserBuyedList } from "../../Redux/Card/actions"
import Spinner from "../../Components/spinner/spinner";
import Image from "../../Assets/noData.png";
const BuyedListing = () => {
    const dispatch = useDispatch();
    const [buyData, setbuyData] = useState([]);
    const buyerListData = useSelector((state) => state?.CardReducer?.userByedList?.buyTrades);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        if (buyerListData !== undefined) {
            setbuyData(buyerListData)
        }
    }, [buyerListData])
    useEffect(() => {
        dispatch(getUserBuyedList({ setLoader: setLoader }));
    }, [])
    return (
        <>
            {
                loader ? (
                    <Spinner />
                ) : <>
                    <div className="container">
                        {buyData?.length > 0 ?
                            (
                                <div className="row">{
                                    buyData?.map((item, index) => {
                                        return (
                                            <div className="col-lg-4 col-md-4 my-4">
                                                <Card data={item?.CarAd} index={index} />
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                            : (
                                <div style={{ marginLeft: "40%" }}>
                                    <img src={Image} alt="" width="100px" className="my-3"/>
                                </div>
                            )}
                    </div>
                </>
            }
        </>
    );
}

export default BuyedListing;