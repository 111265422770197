import React from 'react'
import RegisterForm from "../../Components/RegisterForm"
import styles from "./style.module.scss"

export default function Login() {
  return (
    <div className={`container ${styles.Forms}`}>
    <RegisterForm title="Become a Member for FREE" type ="signup"/>
    </div>
  )
}
