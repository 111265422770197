import React, {useState, useEffect} from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {resetPassword} from "../../Redux/Auth/actions"
import * as Yup from "yup";

//scss
import styles from "./style.module.scss";

const Form = ({ type }) => {
  const dispatch = useDispatch();
  const [btnState, setBtnState] = useState(false);
  let link = window.location.search.split("?")[1];
  const loginUserSchema = Yup.object().shape({
    newpassword: Yup.string().trim().required('Password is required'),
    confirmpassword: Yup.string().trim()
       .oneOf([Yup.ref('newpassword'), null], 'Passwords must match').required("Confirm password is required")
  });
  const formik = useFormik({
    initialValues: {
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema: loginUserSchema,
    onSubmit: (values) => {
      setBtnState(true);
      dispatch(resetPassword({
        newpassword:values.newpassword,
        userId: link,
        setBtnState:setBtnState
      }));
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div className="Container">
      <div className="row">
        <div className="col-12">
          <form onSubmit={formik.handleSubmit} className={styles.Form}>
          <label className="text-start">New Password</label>
                <input
                  fullWidth
                  name="newpassword"
                  type="password"
                  placeholder="New Password"
                  className={styles.input}
                  value={formik.values.newpassword}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.newpassword && formik.errors.newpassword
                  }
                />
                 {formik.touched.newpassword && formik.errors.newpassword ? (
                  <div className={styles.error}>{formik.errors.newpassword}</div>
                ) : (
                  ""
                )}
                <label className="text-start">Confirm Password</label>
                <input
                  fullWidth
                  name="confirmpassword"
                  type="password"
                  placeholder="Confirm Password"
                  className={styles.input}
                  value={formik.values.confirmpassword}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.confirmpassword &&
                    formik.errors.confirmpassword
                  }
                />
                 {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                  <div className={styles.error}>{formik.errors.confirmpassword}</div>
                ) : (
                  ""
                )}
            <button type="submit" disabled={btnState}>Change Password</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
