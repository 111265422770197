import {
  CONTACT_FORM,
  CITY_LIST,
  SINGLE_CITY_DATA,
  SINGLE_CAR_DATA,
  BUYER_LIST,
  SELLER_LIST,
  BUYER_LOGED_LIST,
  SELLER_LOGED_LIST,
  BUY_CAR_FORM,
  SELLER_CAR_FORM,
  USER_LIST,
  EDIT_PROFILE,
  PROSAL_DATA,
  GET_CAR_DATA,
} from "./const";
import { BASE_URL_API } from "../../config/const.js";
import axios from "axios";
import { toast } from "react-toastify";
import { logout } from "../Auth/actions";
import { userList } from "../Auth/actions";

let token = localStorage?.getItem("Token");

export const contactRequest = (payload) => async (dispatch) => {
  const { email, resetForm } = payload;
  let url = `${BASE_URL_API}user/contactUs`;
  let data = {
    email: email,
  };
  try {
    let response = await axios.post(url, data);
    dispatch({
      type: CONTACT_FORM,
      payload: response.data,
    });
    toast.success(response.data.data.message);
    payload.setBtnState(false);
    resetForm();
  } catch (err) {
    toast.error(err.response.data.message);
    payload.setBtnState(false);
  }
};

export const getCarData = (payload) => async (dispatch) => {
  let url = `https://car-data.p.rapidapi.com/cars/makes`;
  let url2 = `https://car-data.p.rapidapi.com/cars/years`;
  try {
    let axiosConfig = {
      headers: {
        "X-RapidAPI-Key": "b56d62be03msh5109cef28b9f399p16d3dajsnf14721b50208",
        "X-RapidAPI-Host": "car-data.p.rapidapi.com",
      },
    };
    let response = await axios.get(url, axiosConfig);
    let getYears = await axios.get(url2, axiosConfig);
    let getModels;
    let exteriorColor;
    // console.log(
    //   "🚀 ~ file: actions.js:65 ~ getCarData ~ payload?.makeYear && payload?.VersionYear:",
    //   payload?.makeYear,
    //   payload?.VersionYear
    // );
    let ModalArray = [];
    let yearArray = [];
    let MakeArray = [];
    let colorArray = [];
    if (payload?.makeYear && payload?.VersionYear) {
      const options = {
        method: "GET",
        url: "https://car-data.p.rapidapi.com/cars",
        params: {
          limit: "10",
          page: "0",
          year: payload.VersionYear?.value,
          make: payload.makeYear?.value,
        },
        headers: {
          "X-RapidAPI-Key":
            "b56d62be03msh5109cef28b9f399p16d3dajsnf14721b50208",
          "X-RapidAPI-Host": "car-data.p.rapidapi.com",
        },
      };

      getModels = await axios.request(options);
      getModels?.data?.map((item) => {
        ModalArray.push({ value: item?.model, label: item?.model });
      });
      console.log({ getModels });
      console.log({ ModalArray });
      // let url3 = `https://car-data.p.rapidapi.com/cars/models?make=${payload.makeYear?.value}&year=${payload.VersionYear?.value}`;
      // let url4 = `https://car-data.p.rapidapi.com/cars/exterior-colors?make=${payload.makeYear?.value}&year=${payload.VersionYear?.value}&model=${payload.modalYear?.value}`;
      // getModels = await axios.get(url3, axiosConfig);
      // exteriorColor = await axios.get(url4, axiosConfig);
    }

    response?.data?.map((item) => {
      MakeArray.push({ value: item, label: item });
    });

    getYears?.data?.map((item) => {
      yearArray.push({ value: item, label: item });
    });
    getModels?.data?.map((item) => {
      ModalArray.push({ value: item?.model, label: item?.model });
    });
    // if (ModalArray.length > 0) {
    //   exteriorColor?.data?.data.map((item) => {
    //     colorArray.push({ value: item.name, label: item.name });
    //   });
    // }
    const uniqueArr = Array.from(
      new Set(colorArray.map((a) => JSON.stringify(a)))
    ).map(JSON.parse);

    let responseData = {
      yearArray: yearArray,
      ModalArray: ModalArray,
      MakeArray: MakeArray,
      colorArray: uniqueArr,
    };
    console.log(
      "🚀 ~ file: actions.js:134 ~ getCarData ~ responseData:",
      responseData
    );

    dispatch({
      type: GET_CAR_DATA,
      payload: responseData,
    });
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
};

export const getCities = () => async (dispatch) => {
  let url = `${BASE_URL_API}carAd/totalAdsInDifferentCities`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: CITY_LIST,
      payload: response.data.data,
    });
  } catch (err) {
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err?.response?.data?.message);
  }
};

export const getSingleCityData = (data) => async (dispatch) => {
  let url = `${BASE_URL_API}carAd/allAdsOfCity/${data.city}`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: SINGLE_CITY_DATA,
      payload: response.data.data,
    });
  } catch (err) {
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err.response.data.message);
  }
};

export const SingleCarData = (data) => async (dispatch) => {
  let url = `${BASE_URL_API}carAd/${data.id}`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    console.log("🚀 ~ file: actions.js:197 ~ SingleCarData ~ response:", response)
    dispatch({
      type: SINGLE_CAR_DATA,
      payload: response.data,
    });
  } catch (err) {
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err.response.data.message);
  }
};
export const getPurposal = (data) => async (dispatch) => {
  let url = `${BASE_URL_API}proposal/allProposalsOfCarAd/${data.id}`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: PROSAL_DATA,
      payload: response.data.data,
    });
  } catch (err) {
    if (err?.response?.status === 401) {
      logout();
    }
    toast.error(err.response?.data.message);
  }
};
export const accpetRejectOffer = (data) => async (dispatch) => {
  let url;
  let payload;
  if (data.proposal) {
    url = `${BASE_URL_API}proposal/proposalAccept`;
    payload = {
      proposelId: data.offerId,
      isAccepted: data.isAccepted,
    };
  } else {
    url = `${BASE_URL_API}buyerOffer/acceptOffer`;
    payload = {
      offerId: data.offerId,
      isAccepted: data.isAccepted,
    };
  }

  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.post(url, payload, axiosConfig);
    toast.success(response.data.data.message);
    window.location.reload();
    data.setLoader(false);
  } catch (err) {
    data.setLoader(false);
    toast.error(err.response.data.message);
  }
};
export const deleteCar = (data) => async (dispatch) => {
  let url = `${BASE_URL_API}carAd/carAdDelete/${data.carId}`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.delete(url, axiosConfig);
    toast.success(response.data.data.message);
    window.location.href = "/profile";
    data.setLoader(false);
  } catch (err) {
    data.setLoader(false);
    toast.error(err.response.data.message);
  }
};
export const getBuyerList = (payload) => async (dispatch) => {
  let url = `${BASE_URL_API}requirmentAd/allAds`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: BUYER_LIST,
      payload: response.data.data,
    });
    payload.setLoader(false);
  } catch (err) {
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err.response.data.message);
  }
};
export const getSellerList = (payload) => async (dispatch) => {
  let url = `${BASE_URL_API}carAd/allSellerAds`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: SELLER_LIST,
      payload: response.data.data,
    });
    payload.setLoader(false);
  } catch (err) {
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err.response.data.message);
  }
};
export const sendOffer = (payload) => async (dispatch) => {
  const { amount, description, addId, currentTime, buyerAddress, signature } =
    payload;
  let url = `${BASE_URL_API}buyerOffer/sendOffer`;
  let data = {
    amount: amount,
    description: description,
    adId: addId,
    currentTime,
    buyerAddress,
    signature: signature,
  };
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.post(url, data, axiosConfig);
    payload.setOpen(false);
    payload.setBtnState(false);
    dispatch({
      type: SELLER_LIST,
      payload: response.data.data,
    });
    toast.success(response.data.data.message);
    payload.setLoader(false);
    window.location.reload();
  } catch (err) {
    payload.setOpen(false);
    toast.error(err.response.data.message);
  }
};
export const buyCarApi = (payload) => async (dispatch) => {
  const { carAdId, buyerId } = payload;
  let url;
  let data;
  if (payload.proposal) {
    url = `${BASE_URL_API}proposal/proposalAccept`;
    data = {
      proposelId: payload.offerId,
      isAccepted: payload.isAccepted,
    };
  } else {
    url = `${BASE_URL_API}trade/tradeCreate`;
    data = {
      carAdId: carAdId,
      buyerId: buyerId,
    };
  }
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.post(url, data, axiosConfig);
    toast.success(response.data.data.message);
    window.location.href = "/profile";
  } catch (err) {
    payload.setLoader(false);
    toast.error(err.response.data.message);
  }
};

export const getLogedBuyerList = (payload) => async (dispatch) => {
  let url = `${BASE_URL_API}requirmentAd/myallAds`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: BUYER_LOGED_LIST,
      payload: response.data.data,
    });
    payload.setLoader(false);
  } catch (err) {
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err.response.data.message);
  }
};
export const getLogedSellerList = (payload) => async (dispatch) => {
  let url = `${BASE_URL_API}carAd/myAllAds`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: SELLER_LOGED_LIST,
      payload: response.data.data,
    });
    payload.setLoader(false);
  } catch (err) {
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err.response.data.message);
  }
};
export const postBuyForm = (payload) => async (dispatch) => {
  const {
    Mileage,
    Price,
    Description,
    contactNumber,
    exteriorColor,
    registeredIn,
    make,
    verient,
    model,
    features,
    carNumber,
    resetForm,
  } = payload;
  let url = `${BASE_URL_API}requirmentAd/create`;
  let data = {
    mileage: Mileage,
    price: Price,
    description: Description,
    contactNumber: contactNumber,
    exteriorColor: exteriorColor,
    registerIn: registeredIn,
    make: make,
    verient: verient,
    model: model,
    // carNumber:carNumber,
    features: features,
  };
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.post(url, data, axiosConfig);
    dispatch({
      type: BUY_CAR_FORM,
      payload: response.data,
    });
    // payload.setLoader(false);
    toast.success(response.data.data.message);
    // payload.setLoader(false)
    // payload.setBtnState(false);
    // resetForm();
    window.location.href = `/profile`;
  } catch (err) {
    toast.error(err.response.data.message);
    payload.setBtnState(false);
    payload.setLoader(false);
  }
};
export const postSellerForm = (payload) => async (dispatch) => {
  payload.setLoader(true);
  const {
    Mileage,
    Price,
    PlatformFee,
    Description,
    contactNumber,
    exteriorColor,
    registeredIn,
    image,
    make,
    verient,
    model,
    features,
    isProposal,
    resetForm,
    carNumber,
    carAdId,
    tokenId,
  } = payload;
  console.log("🚀 ~ file: actions.js:530 ~ postSellerForm ~ payload:", payload)
  let url = `${BASE_URL_API}carAd/createNewAd`;
  const formData = new FormData();
  // let data = {
  formData.append("mileage", Mileage);
  formData.append("price", Price);
  formData.append("platformFee", PlatformFee);
  formData.append("description", Description);
  formData.append("contactNumber", contactNumber);
  formData.append("exteriorColor", exteriorColor);
  formData.append("registerIn", registeredIn);
  formData.append("carNumber", carNumber);
  // formData.append("verient", verient);
  formData.append("isProposal", isProposal);
  formData.append("tokenId", tokenId);
  console.log("🚀 ~ file: actions.js:546 ~ postSellerForm ~ image:", image)
  if (image) {
    for (let index = 0; index < image.length; index++) {
      const element = image[index];
      console.log("🚀 ~ file: actions.js:549 ~ postSellerForm ~ element:", element)
      formData.append("image", element, element.name);
    }
  }
  formData.append("make", make);
  formData.append("verient", verient);
  formData.append("model", model);
  formData.append("carAdId", carAdId ? carAdId : null);
  formData.append("features", features);
  // };
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.post(url, formData, axiosConfig);
    console.log("🚀 ~ file: actions.js:566 ~ postSellerForm ~ response:", response)
    dispatch({
      type: SELLER_CAR_FORM,
      payload: response.data,
    });
    // payload.setLoader(false);
    toast.success(response.data.data.message);
    payload.setBtnState(false);
    payload.setLoader(false);
    resetForm();
    setTimeout(()=>{
    window.location.href = `/profile`;
    }, 1100)
    payload.setLoader(false)
  } catch (err) {
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err.response.data.data.message);
    payload.setBtnState(false);
    payload.setLoader(false);
  }
};

export const getUserBuyedList = (payload) => async (dispatch) => {
  let url = `${BASE_URL_API}trade/getTrades`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.get(url, axiosConfig);
    dispatch({
      type: USER_LIST,
      payload: response.data.data,
    });
    payload.setLoader(false);
  } catch (err) {
    // payload.setLoader(false);
    if (err?.response.status === 401) {
      logout();
    }
    toast.error(err.response.data.data.message);
  }
};
export const editUserProfile = (payload) => async (dispatch) => {
  let url = `${BASE_URL_API}user/editProfile`;
  try {
    let axiosConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await axios.post(url, payload.data, axiosConfig);
    dispatch({
      type: SELLER_CAR_FORM,
      payload: response.data.data,
    });
    toast.success("Profile Edit successfully");
    window.location.href = `/profile`;
    dispatch(userList());
    payload.setBtnState(false);
    payload.resetForm();
  } catch (err) {
    console.log(err?.response, "reposne");
    toast.error(err?.response?.data?.data.message);
    payload.setBtnState(false);
  }
};
