import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLogin, logout } from "../../Redux/Auth/actions";
import Logo from "../../Assets/logo.png";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import WalletConnectionButton from '../connectWallet/index';
import { Avatar } from "@material-ui/core";
//style import
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import profileImg from "../../Assets/profile.png"
import dashboarImg from "../../Assets/dashboard.png"
import logoutImg from "../../Assets/logout.png"
import Overlay from 'react-bootstrap/Overlay';
let anchor = "right"
export default function Header({ setsignerData, signerData, setAddress, address }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  const handleDropdown = () => {
    setShow(!show)
  }
  return (
    <>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        className={`${styles.drawer}`}
      >
        {isLogin() ? (
          <List onClick={handleLogout}>
            <ListItem disablePadding>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>) : (
          <>
            <List>
              <ListItem disablePadding>
                <Link to="/login" className={`${styles.listDrop}`}>
                  <ListItemText primary="Member Login" />
                </Link>
              </ListItem>
            </List>
            <List>
              <ListItem disablePadding>
                <Link to="/signup" className={`${styles.listDrop}`}>
                  <ListItemText primary="Become a Member" />
                </Link>
              </ListItem>
            </List>
          </>
        )}

        <List>
          <ListItem disablePadding>
            <Link to="/" className={`${styles.listDrop}`}>
              <ListItemText primary="Home" />
            </Link>
          </ListItem>
        </List>
        {isLogin() && (
          <>
            <List>
              <ListItem disablePadding>
                <WalletConnectionButton setsignerData={setsignerData} signerData={signerData} address={address} setAddress={setAddress} />
              </ListItem>
            </List>
            <List>
              <ListItem disablePadding>
                <Link to="/profile" className={`${styles.listDrop}`}>
                  <ListItemText primary="Profile" />
                </Link>
              </ListItem>
            </List>
            {/* <List>
              <ListItem disablePadding>
                <Link to="/dashboard" className={`${styles.listDrop}`}>
                  <ListItemText primary="Dashboard" />
                </Link>
              </ListItem>
            </List> */}
            <List>
              <ListItem disablePadding>
                <Link to="/CreateListing?byCar" className={`${styles.listDrop}`}>
                  <ListItemText primary="Buy A Car" />
                </Link>
              </ListItem>
            </List>
            <List>
              <ListItem disablePadding>
                <Link to="/CreateListing?sellCar" className={`${styles.listDrop}`}>
                  <ListItemText primary="Sell My Car" />
                </Link>
              </ListItem>
            </List>
          </>
        )}
        <List>
          <ListItem disablePadding>
            <Link to="/aboutUs" className={`${styles.listDrop}`}>
              <ListItemText primary="About" />
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <div className={`${styles.HeaderMain}`}>
        <div className={`${styles.Header} py-3`}>
          <div className="mx-lg-5">
            <nav className="mx-2 mb-1 navbar navbar-expand-lg navbar-light navBar p-0">
              <div className={`col col-lg-2 text-start col-sm-6 ${styles.logo}`}>
                <Link to="/">
                  <img alt="" src={Logo} />
                </Link>
              </div>
              <div className={isLogin() ? "col-lg-7" : "col-lg-4"}>
                <button onClick={toggleDrawer(anchor, true)} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <div className={`${styles.list}`}>
                    <ul className="justify-content-center">
                      <Link to="/">
                        <li>Home</li>
                      </Link>
                      {isLogin() && (
                        <Link>
                          <li className={styles.dropdown}>Create AD
                            <ul>
                              <li onClick={() => { window.location.href = "/CreateListing?sellCar" }}>
                                Sell My Car
                              </li>
                              <li onClick={() => { window.location.href = "/CreateListing?buyCar" }}>
                                Buy A Car
                              </li>
                            </ul>
                          </li>
                        </Link>
                      )}
                      <Link to="/aboutUs">
                        <li>About</li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
              {isLogin() ? (
                <div className="col-lg-3">
                  <div className={`${styles.list}`}>
                
                    <ul>
                      <li>
                        {/* <SettingsIcon /> */}
                        <div className="d-lg-flex d-none">
                          <WalletConnectionButton setsignerData={setsignerData} signerData={signerData} address={address} setAddress={setAddress} />
                          <Link>
                          <li className={styles.dropdownAvatar}>
                              <Avatar
                            className={`mx-3 ${styles.avatar}`}
                            ref={target}
                          //  src={userInfo?.length > 0 && userInfo[0].User.image}
                          />
                            <ul>
                            <li>
                                    <Link to="/profile" > <img src={profileImg} /> <span className="mx-0">Profile</span></Link>
                                  </li>
                                  {/* <li>
                                    <Link to="/dashboard"><img src={dashboarImg} /><span>Dasboard</span></Link>
                                  </li> */}
                                  <li onClick={handleLogout}>
                                    <img src={logoutImg} /> <span>Logout</span>
                                  </li>
                            </ul>
                          </li>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="col-lg-6">
                  <div className={`${styles.list}`}>
                    <ul>
                      <Link to="/login" className={`d-lg-flex d-none mx-4 ${styles.traingbuton}`}>
                        <li>
                          <button>Member Login</button>
                        </li>
                      </Link>
                      <Link to="/signup" className={`d-lg-flex d-none mx-4 ${styles.traingbuton}`}>
                        <li>
                          <button>Become a Member</button>
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              )}
            </nav>
          </div>
        </div>
      </div>
    </>

  );
}
