import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../CarListing/style.module.scss";
import Card from "../../Components/Card/index"
import { getLogedBuyerList } from "../../Redux/Card/actions"
import Spinner from "../../Components/spinner/spinner";
import Image from "../../Assets/noData.png";
const BuyListing = () => {
    const dispatch = useDispatch();
    const buyerListData = useSelector((state) => state?.CardReducer?.buyerLogedList?.allAds);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        dispatch(getLogedBuyerList({ setLoader: setLoader }))
    }, [])
    return (
        <>
            {
                loader ? (
                    <Spinner />
                ) : <>
                    <div className="container my-5">
                        <div className="row mt-5">
                            <div className={`col-lg-12 my-5 ${styles.nearBy}`}>
                                <h1>
                                    <span>
                                        Featured places to stay
                                    </span>
                                </h1>
                                <span>Popular places to stay that Chisfis recommends for you
                                </span>
                            </div>
                        </div>
                        {buyerListData?.length > 0 ?
                            (
                                buyerListData?.map((item, index) => {
                                    return (
                                        <>
                                            <Card data={item} index={index} />
                                        </>
                                    )
                                })
                            )
                            : (
                                <div style={{ marginLeft: "40%" }}>
                                    <img src={Image} alt="" width="100px" />
                                </div>
                            )}

                    </div>
                </>
            }
        </>
    );
}

export default BuyListing;