import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Pages/HomePage";
import SignIn from "./Pages/SignIn/index";
import SignUp from "./Pages/SignUp/signUp";
import ResetPassword from "./Pages/ResetPassword/resetPassword";
import ForgotPassword from "./Components/ForgetForm"
import Contact from "./Pages/Contact";
import Price from "./Pages/Pricing";
import UserList from "./Components/Table"
import ErrorPage from "./Pages/404Page/errorPage"
import detailCars from "./Pages/CarListing/detailCars";
import CreateListing from "./Pages/CarListing/createListing"
import Bridge from "./Pages/Bridge"
import allListing from "./Pages/CarListing/allListing"
import TermsAndCondition from "./Pages/Terms&Condition";
import nearBy from "./Pages/HomePage/neayByListing";
import Faq from "./Pages/Faq's"
//routes here
import PrivateRoute from "./Routes/privateRoutes";
import PublicRoute from "./Routes/publicRoute";

//redux
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import store from './Redux/store';
import persistor from './Redux/persistStore';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { isLogin } from "./Redux/Auth/actions";
// import configureStore from "./Redux/store";

//scss
import "./App.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Profile from "./Pages/profile";
import Dashboard from "./Pages/dashboard";
import BuyListing from "./Pages/Listing/buyListing";
import SellerListing from "./Pages/Listing/sellListing";
import BuyedListing from "./Pages/Listing/buyedListing";
import SoldListing from "./Pages/Listing/soldListing";
import EditProfile from "./Pages/EditProfile/EditProfile"
import aboutUs from "./Pages/aboutUs/aboutUs";

// const store = configureStore();

// let Admin = localStorage.getItem("Admin")

function App() {
  const [signerData, setsignerData] = useState()
  const [address, setAddress] = React.useState();
  return (
    <GoogleOAuthProvider clientId="452120701431-59laf02b20gk268h84pvapk4sf6pbo9j.apps.googleusercontent.com">
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <div className="App">
                <Header setsignerData={setsignerData} signerData={signerData} address={address} setAddress={setAddress}/>
                <Switch>
                  {/* {Admin && <PublicRoute component={UserList} path="/userList" exact />} */}
                  <PublicRoute
                    restricted={false}
                    component={SignUp}
                    path="/signup"
                    exact
                  />
                  <PublicRoute
                    restricted={true}
                    component={SignIn}
                    path="/login"
                    exact
                  />
                  <PublicRoute
                    restricted={true}
                    component={ResetPassword}
                    path="/resetpassword"
                    exact
                  />
                  <PrivateRoute
                    restricted={true}
                    component={detailCars}
                    path="/detailCar"
                    exact
                  />
                   <PrivateRoute
                    restricted={true}
                    component={CreateListing}
                    path="/CreateListing"
                    exact
                  />
                   <PrivateRoute
                    restricted={true}
                    component={Bridge}
                    path="/bridge"
                    exact
                  />
                   <PrivateRoute
                    restricted={true}
                    component={TermsAndCondition}
                    path="/TermsAndCondition"
                    exact
                  />
                   <PrivateRoute
                    restricted={true}
                    component={Faq}
                    path="/Faq"
                    exact
                  />
                  {isLogin() ? <PrivateRoute
                    restricted={true}
                    component={aboutUs}
                    path="/aboutUs"
                    exact
                  /> : <PublicRoute
                    restricted={true}
                    component={aboutUs}
                    path="/aboutUs"
                    exact
                  />
                  }

                  {isLogin() ? <PrivateRoute
                    restricted={true}
                    component={nearBy}
                    path="/nearBy"
                    exact
                  /> : <PublicRoute
                    restricted={true}
                    component={nearBy}
                    path="/nearBy"
                    exact
                  />
                  }
                  
                  <PrivateRoute
                    restricted={true}
                    component={Profile}
                    path="/profile"
                    exact
                  />
                  <PrivateRoute
                    restricted={true}
                    component={EditProfile}
                    path="/EditProfile"
                    exact
                  />
                  <PrivateRoute
                    restricted={true}
                    component={Dashboard}
                    path="/dashboard"
                    exact
                  />
                   <PrivateRoute
                    restricted={true}
                    component={BuyListing}
                    path="/buyListing"
                    exact
                  />
                  <PrivateRoute
                    restricted={true}
                    component={SellerListing}
                    path="/sellListing"
                    exact
                  />
                      <PrivateRoute
                    restricted={true}
                    component={BuyedListing}
                    path="/buyedListing"
                    exact
                  />
                  <PrivateRoute
                    restricted={true}
                    component={SoldListing}
                    path="/soldListing"
                    exact
                  />
                   <PrivateRoute
                    restricted={true}
                    component={allListing}
                    path="/allListing"
                    exact
                  />
                  <PublicRoute
                    restricted={true}
                    component={ForgotPassword}
                    path="/forgotPassword"
                    exact
                  />
                  <PublicRoute component={Home} path="/" exact />
                  <PublicRoute component={Contact} path="/contact" exact />
                  <PublicRoute component={Price} path="/pricing" exact />
                  <PublicRoute component={ErrorPage} path="*" exact />
                  {/* <PrivateRoute component={Contact} path="/contact" exact />
            <PrivateRoute component={Price} path="/pricing" exact /> */}
                </Switch>
              </div>
              <Footer />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </Suspense>
    </GoogleOAuthProvider>
  );
}

export default App;
