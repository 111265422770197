import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import hondaLogo from "../../Assets/honda.png";
import ToyotaLogo from "../../Assets/toyota.png";
import mazdaLogo from "../../Assets/mazda.png";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//style
import moment from 'moment';

import styles from "./style.module.scss"
import { Link } from 'react-router-dom';

const MainSlider =({img,data})=>{
    return (
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={58}
        totalSlides={img?.length > 0 ? img?.length:1}
        className={styles.slider}
      ><Link to={`/detailCar?${data?.id}`}>
        {img?.length > 0 ? (
        <Slider>
          {img?.map((item, index)=>{
            return(
              <Slide index={index}><img src={item?.url} alt="" /></Slide>
            )
          })}
        </Slider>
        ):(
        <Slider>
         <Slide index="0"><img src={data?.make === "Toyota" ? ToyotaLogo : data?.make === "Honda" ? hondaLogo : mazdaLogo} alt="" /></Slide>
        </Slider>
        )}
        </Link>
        <ButtonBack  className={`${img?.length > 1 ? styles.backbutton :"d-none"}`}><ChevronLeftIcon/></ButtonBack>
        <ButtonNext className={`${img?.length > 1 ? styles.nextbutton :"d-none"}`}><ChevronRightIcon /></ButtonNext>
        <span className={styles.favouti}>{moment(data?.createdAt).format('YYYY-MM-DD')}</span>
      </CarouselProvider>
    );
  }
export default MainSlider;