import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import ROUTER from "../../contracts/router.json";

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;
`;

const FormWrapper = styled.div`
  display: flex;
  height: 60vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vh;

  max-width: 600px;
  padding: 40px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
`;

const InputWrapper = styled.div`
  padding: 2px;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 18px;
  text-align: right;
  background-color: white;
  border-radius: 20px;
  color: #ff8505;
  border-radius: 15px;

  &:focus {
    outline: none;
    border-bottom-color: #6c63ff;
  }

  &::placeholder {
    color: #ccc;
  }
`;

const InputLabelRight = styled.label`
  position: absolute;
  top: -30%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #FF8505;
  transition: font-size 0.2s ease-in-out, top 0.2s ease-in-out;

  ${Input}:focus ~ &,
  ${Input}:not(:placeholder-shown) ~ & {
    font-size: 14px;
    top: 0;
    color: #6c63ff;
  }
`;

const InputLabelLeft = styled.label`
  position: absolute;
  top: -30%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
  transition: font-size 0.2s ease-in-out, top 0.2s ease-in-out;

  ${Input}:focus ~ &,
  ${Input}:not(:placeholder-shown) ~ & {
    font-size: 14px;
    top: 0;
    color: #6c63ff;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #24e329;
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #24e32980;
  }
`;
export default function Bridge() {
  const [account, setAccount] = useState();
  const [inputAmount, setInputAmount] = useState();
  const [provider, setProvider] = useState();
  const [signer, setSigner] = useState();
  const [balance, setBalance] = useState(0.0);
  const [roshAmount, setRoshAmount] = useState(0.0);
  const [chainid, setChainId] = useState(0.0);
  const [balanceError, setBalanceError] = useState(true);
  //   console.log(amount);

  const onSubmit = async () => {
    let tx = {
      to: "0x6079b860c0b2bFa60359f5d118D54A9a92fc3eD7",
      value: ethers.utils.parseEther(inputAmount),
    };

    try {
      signer.sendTransaction(tx).then((txObj) => {
        console.log("txHash", txObj.hash);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPrice = async (event) => {
    setInputAmount(event.target.value);
    let amount = event.target.value;

    if (amount > balance) {
      setBalanceError(true);
      return;
    }

    setBalanceError(false);
    const router = new ethers.Contract(ROUTER.address, ROUTER.abi, provider);
    const comision = amount * 0.05;
    amount = amount - comision;
    console.log("Percentage amount: ", comision, amount.toString());
    // try {
    const tokens = [
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", //wrapped eth address
      "0xdAC17F958D2ee523a2206206994597C13D831ec7", //usdt address
    ];
    const time = Math.floor(Date.now() / 1000) + 200000;
    const deadline = ethers.BigNumber.from(time);
    console.log("amount is here: ", amount.toString());
    const amountIn = ethers.utils.parseEther(amount.toString());
    const amountOut = await router.getAmountsOut(amountIn, tokens);
    console.log(amountOut[1].toString() / 1000000);
    const totalUsdt = amountOut[1].toString() / 1000000;
    const rosh = totalUsdt / 0.015;
    setRoshAmount(rosh);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleWallet = async () => {
    console.log(window.ethereum);

    if (window.ethereum) {
      await window.ethereum.enable();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const { chainId } = await provider.getNetwork();
      if (chainId === 1) {
        setProvider(provider);
        const signers = provider.getSigner();
        setSigner(signers);

        const balance = await signers.getBalance();
        setBalance(parseFloat(ethers.utils.formatUnits(balance)).toFixed(4));
        console.log(balance);
        // Set signer
        const account = await signers.getAddress();
        setAccount(account);
      } else {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{ chainId: `0x${Number(1).toString(16)}` }],
        });
      }
    }
  };

  useEffect(() => {
    handleWallet();
  }, []);

  return (
    <FormContainer>
      <FormWrapper>
        <h1
          style={{ marginBottom: "1.5rem", fontSize: "25px", color: "#FF8505" }}
        >
          BUY ROSH
        </h1>
        <h1
          style={{ marginBottom: "1.5rem", fontSize: "15px", color: "#24e329" }}
        >
          Please connect to <b>Ethereum</b> mainnet
        </h1>

        <InputWrapper>
          <InputLabelRight htmlFor="from">
            {" "}
            Balance: {balance === 0 ? 0 : balance}{" "}
          </InputLabelRight>
          <InputLabelLeft htmlFor="from">ETH</InputLabelLeft>
          <Input
            type="number"
            min="0"
            onChange={handleGetPrice}
            placeholder="0.0"
          />
        </InputWrapper>
        <InputWrapper>
          <InputLabelLeft htmlFor="from">ROSH</InputLabelLeft>
          <Input type="number" value={roshAmount.toFixed(2)} disabled />
        </InputWrapper>
        <h1
          style={{ marginBottom: "1.5rem", fontSize: "15px", color: "#FF8505" }}
        >
          5% platform fee will be charged
        </h1>
        {balanceError == true || inputAmount === 0 ? (
          <SubmitButton disabled>Insufficient Balance</SubmitButton>
        ) : (
          <SubmitButton onClick={onSubmit}>BUY</SubmitButton>
        )}
      </FormWrapper>
    </FormContainer>
  );
}
