import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Login, googleLogin, facebookLogin } from "../../Redux/Auth/actions";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
// import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from "react-facebook-login";
import * as Yup from "yup";
import Spinner from "../../Components/spinner/spinner";

//scss
import styles from "./style.module.scss";

const Form = ({ type }) => {
  const dispatch = useDispatch();
  const [btnState, setBtnState] = useState(false);
  const [loader, setLoader] = useState(false);

  const loginUserSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Please enter valid email address")
      .required("Please enter email address"),
    password: Yup.string().trim().required("Please enter password"),
    // .matches(
    //   /^(?=(?:.*[A-Z].*){1})(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   "Must Contain 8 Characters,  One Uppercase, One Lowercase, One Number and one special case Character"
    // )
  });
  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    enableReinitialize: true,
    validationSchema: loginUserSchema,
    onSubmit: (values) => {
      setLoader(true);
      setBtnState(true);
      dispatch(
        Login({
          password: values.password,
          email: values.email,
          setBtnState: setBtnState,
          setLoader: setLoader,
        })
      );
    },
  });
  // const googleLogin = useGoogleLogin({
  //   onSuccess: credentialResponse => credentialResponse.log(data, "here data"),
  // });
  // const responseFacebook = (response) => {
  //   let facebookId = response.id;
  //   let userName = response.name;
  //   dispatch(
  //     facebookLogin({
  //       facebookId: facebookId,
  //       userName:userName,
  //     })
  //   )
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <div className="Container">
          <div className="row">
            <div className="col-12">
              <form onSubmit={formik.handleSubmit} className={styles.Form}>
                <label className="text-start">Email *</label>
                <input
                  fullWidth
                  className={styles.input}
                  name="email"
                  placeholder="Enter Your Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className={styles.error}>{formik.errors.email}</p>
                ) : (
                  ""
                )}
                <label className="text-start">Password *</label>
                <input
                  fullWidth
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  className={styles.input}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  helperText={formik.touched.password && formik.errors.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className={styles.error}>{formik.errors.password}</p>
                ) : (
                  ""
                )}
                <div className="col-lg-12">
                  <button type="submit" disabled={btnState}>
                    Sign In
                  </button>
                </div>
                <div className={`col-lg-12 ${styles.socialtext}`}>
                  <a href="/forgotPassword" className="float-start">
                    Forget Password ?
                  </a>
                  <a href="/signup" className="float-end">
                    Become a member
                  </a>
                </div>
              </form>
              {/* <div className="row mx-0">
            <div className={`col-lg-12 ${styles.socialtext}`}>
              <span>or continue with</span>
            </div> */}
              {/* <div className={`col-lg-12 ${styles.socialLogin}`}>
              <GoogleLogin
              width="368px"
              height="450px"
              context="Google"
              text="Google"
              buttonText="Google"
                onSuccess={(credentialResponse) => {
                  dispatch(
                    googleLogin({
                      idToken: credentialResponse.credential,
                    })
                  );
                }}
                onError={() => {
                  toast.error("Login Failed");
                }}
              />
              <FacebookLogin
                appId="652637439522601"
                autoLoad={false}
                textButton="Facebook"
                icon="fa-facebook"
                cssClass={styles.butonfacebook}
                fields="name,email,picture"
                // onClick={responseFacebook}
                callback={responseFacebook}
              />
            </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Form;
