import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  trustWalletFunction,
  signersWallet,
  addresses,
  coinbaseWalletFunction,
  metamaskFunction,
} from "../../walletConnect";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import metaMaskImg from "../../Assets/metamask.png";
import coinBaseImg from "../../Assets/coinbase.png";
import trustWalletImg from "../../Assets/trustwallet.png";
import styles from "./style.module.scss";
import { userList } from "../../Redux/Auth/actions";

const WalletConnectionButton = ({
  setsignerData,
  signerData,
  setAddress,
  address,
}) => {
  const userData = useSelector(
    (state) => state?.authReducer?.profileData?.data
  );
  // console.log(userData, "jkhgf")
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const walletCheck = localStorage.getItem("wallet");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  useEffect(() => {
    dispatch(userList());
  }, []);
  const trustWalletConnection = async () => {
    await trustWalletFunction();
    if (signersWallet !== undefined && addresses !== undefined) {
      if (userData?.walletAddress === addresses) {
        setsignerData(signersWallet);
        setAddress(addresses);
        localStorage.setItem("wallet", "trustwallet");
      } else {
        toast.error("Please registered your wallet from profile");
      }
    }
    setOpen(false);
  };
  const coinbaseWalletConnection = async () => {
    await coinbaseWalletFunction();
    if (signersWallet !== undefined && addresses !== undefined) {
      if (userData?.walletAddress === addresses) {
        setsignerData(signersWallet);
        setAddress(addresses);
        localStorage.setItem("wallet", "coinBase");
      } else {
        toast.error("Please registered your wallet from profile");
      }
    }
    setOpen(false);
  };
  const metamaskConnection = async () => {
    await metamaskFunction();
    if (signersWallet !== undefined && addresses !== undefined) {
      if (userData?.walletAddress === addresses) {
        setsignerData(signersWallet);
        setAddress(addresses);
        localStorage.setItem("wallet", "metamask");
      } else {
        toast.error("Please registered your wallet from profile");
      }
    }
    setOpen(false);
  };

  useEffect(() => {
    if (walletCheck !== null && walletCheck !== undefined) {
      if (walletCheck === "trustwallet") {
        trustWalletConnection();
      }
      if (walletCheck === "metamask") {
        metamaskConnection();
      }
      if (walletCheck === "coinBase") {
        coinbaseWalletConnection();
      }
    }
  }, [walletCheck]);

  console.log(addresses, "addresses")

  return (
    <div className="Container">
      <div className="row">
        {address === undefined ? (
          <Button className="mt-1" onClick={handleOpen}>
            Connect wallet
          </Button>
        ) : (
          <Button className="mt-1">
            {" "}
            {address.slice(0, 5) + "..." + address.slice(37, 42)}{" "}
          </Button>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        className={styles.connectModal}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Wallet for connection
          </Typography>
          {/* <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={trustWalletConnection}
          >
            <img src={trustWalletImg} />
            Trust Wallet
          </Typography> */}
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={coinbaseWalletConnection}
          >
            <img src={coinBaseImg} />
            Coinbase Wallet
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            onClick={metamaskConnection}
          >
            <img src={metaMaskImg} /> Metamask
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default WalletConnectionButton;
