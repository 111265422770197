import React from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";

let signersWallet;
let addresses;
let balance;

const trustWalletFunction = async () => {
  try {
    if (window.trustwallet.isTrustWallet) {
      const provider = new ethers.providers.Web3Provider(window.trustwallet);
      signersWallet = provider.getSigner();
      const accounts = await provider.send("eth_requestAccounts", []);
      balance = await provider.getBalance(accounts);
      addresses = accounts[0];
    } else {
      toast.error("Please install TrustWallet Extension");
    }
  } catch (err) {
    console.log(err);
  }
};
const coinbaseWalletFunction = async () => {
  const mediaQuery = window.matchMedia("(max-width: 768px)");
  if (mediaQuery.matches === true) {
    if (window.ethereum) {
      await window.ethereum.enable();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      signersWallet = provider.getSigner();
      const { chainId } = provider.getNetwork();
      if (chainId !== 398) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${Number(398).toString(16)}`,
              chainName: "Argon",
              rpcUrls: ["https://rpc-mainnet.argonteq.com/"],
              nativeCurrency: {
                name: "ARG",
                symbol: "ARG",
                decimals: 18,
              },
              blockExplorerUrls: ["https://scan.argonteq.com/"],
            },
          ],
        });
      }
      const accounts = await signersWallet.getAddress();
      addresses = accounts;
    } else {
      const url = "https://go.cb-w.com/dapp?cb_url=carmammas.com";
      window.location.href = url;
    }
  } else {
    try {
      if (window.coinbaseWalletExtension) {
        const provider = new ethers.providers.Web3Provider(
          window.coinbaseWalletExtension
        );
        signersWallet = provider.getSigner();
        const { chainId } = provider.getNetwork();

        if (chainId !== 398) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${Number(398).toString(16)}`,
                chainName: "Argon",
                rpcUrls: ["https://rpc-mainnet.argonteq.com/"],
                nativeCurrency: {
                  name: "ARG",
                  symbol: "ARG",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://scan.argonteq.com/"],
              },
            ],
          });
        }
        // const accounts = await provider.send("eth_requestAccounts", []);
        const accounts = await signersWallet.getAddress();
        addresses = accounts;
      } else {
        toast.error("Please install CoinbaseWallet Extension");
      }
    } catch (err) {
      toast.error(err);
    }
  }
};
const metamaskFunction = async () => {
  const mediaQuery = window.matchMedia("(max-width: 768px)");
  if (mediaQuery.matches === true) {
    if (window.ethereum) {
      await window.ethereum.enable();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      signersWallet = provider.getSigner();
      console.log("🚀 ~ metamaskFunction ~ signersWallet:", signersWallet)
      const { chainId } = provider.getNetwork();
      if (chainId !== 398) {
        await window.ethereum.request({
          // method: "wallet_addEthereumChain",
          // params: [
          //   {
          //     chainId: `0x${Number(398).toString(16)}`,
          //     chainName: "Argon",
          //     rpcUrls: ["https://rpc-mainnet.argonteq.com"],
          //     nativeCurrency: {
          //       name: "ARG",
          //       symbol: "ARG",
          //       decimals: 18,
          //     },
          //     blockExplorerUrls: ["https://scan.argonteq.com/"],
          //   },
          // ],
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${Number(398).toString(16)}` }],
        });
      }
      const accounts = await signersWallet.getAddress();
      console.log("🚀 ~ metamaskFunction ~ accounts:", accounts)
      addresses = accounts;
    } else {
      const url = "https://metamask.app.link/dapp/carmammas.com";
      window.location.href = url;
    }
  } else {
    try {
      if (window.ethereum) {
        await window.ethereum.enable();
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        signersWallet = provider.getSigner();
        const { chainId } = provider.getNetwork();
        if (chainId !== 398) {
          await window.ethereum.request({
            // method: "wallet_addEthereumChain",
            // params: [
            //   {
            //     chainId: `0x${Number(398).toString(16)}`,
            //     chainName: "Argon",
            //     rpcUrls: ["https://rpc-mainnet.argonteq.com/"],
            //     nativeCurrency: {
            //       name: "ARG",
            //       symbol: "ARG",
            //       decimals: 18,
            //     },
            //     blockExplorerUrls: ["https://scan.argonteq.com/"],
            //   },
            // ],
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${Number(398).toString(16)}` }],
          });
        }
        const accounts = await signersWallet.getAddress();
        addresses = accounts;
      } else {
        window.open("https://metamask.io/download/", "_blank");
      }
    } catch (err) {
      toast.error(err);
    }
  }
};
export {
  trustWalletFunction,
  signersWallet,
  addresses,
  metamaskFunction,
  coinbaseWalletFunction,
};
