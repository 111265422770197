import { CONTACT_FORM, GET_CAR_DATA,CITY_LIST,PROSAL_DATA, SINGLE_CITY_DATA, MY_PURCHASE,SINGLE_CAR_DATA, BUYER_LIST, SELLER_LIST, SELLER_LOGED_LIST, USER_LIST, BUYER_LOGED_LIST, SELLER_CAR_FORM, BUY_CAR_FORM, EDIT_PROFILE } from "./const"
const initialState = {
  contact: "",
  citiList: [],
  singleCityData: "",
  singleCarData: "",
  buyerList: [],
  sellerList: [],
  userByedList: [],
  buyerLogedList: [],
  sellerLogedList: [],
  buyCarForm: [],
  EDIT_PROFILE: [],
  myPurchase:[],
  proposalData:[],
  getCarData:[]
};

export default (state = initialState, action) => {

  switch (action.type) {
    case CONTACT_FORM:
      return {
        ...state,
        contact: action.payload,
      }
      case GET_CAR_DATA:
      return {
        ...state,
        getCarData: action.payload,
      }
      
    case CITY_LIST:
      return {
        ...state,
        citiList: action.payload,
      };
      case PROSAL_DATA:
        return {
          ...state,
          proposalData: action.payload,
        };
      case MY_PURCHASE:
        return {
          ...state,
          myPurchase: action.payload,
        };
    case SINGLE_CITY_DATA:
      return {
        ...state,
        singleCityData: action.payload,
      };
    case SINGLE_CAR_DATA:
      return {
        ...state,
        singleCarData: action.payload,
      };
    case BUYER_LIST:
      return {
        ...state,
        buyerList: action.payload,
      };
    case SELLER_LIST:
      return {
        ...state,
        sellerList: action.payload,
      };
    case BUYER_LOGED_LIST:
      return {
        ...state,
        buyerLogedList: action.payload,
      };
    case SELLER_LOGED_LIST:
      return {
        ...state,
        sellerLogedList: action.payload,
      };
    case BUY_CAR_FORM:
      return {
        ...state,
        buyCarForm: action.payload,
      };
    case SELLER_CAR_FORM:
      return {
        ...state,
        sellerCarForm: action.payload,
      };
    case USER_LIST:
      return {
        ...state,
        userByedList: action.payload,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        editUserProfile: action.payload,
      };
    default:

      return state;
  }
};