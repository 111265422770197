import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  sendOffer,
  buyCarApi,
  accpetRejectOffer,
} from "../../Redux/Card/actions";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { signersWallet, addresses } from "../../walletConnect";
import carMamaMarketPlace from "../../contracts/CarMammasMarketplace.json";
import carMammasNFT from "../../contracts/CarMammaVehicleRegistration.json";
import USDT from "../../contracts/usdt.json";
import Spinner from "../../Components/spinner/spinner";
//styles imort
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

export default function BuyCard({ id, userData, carData, proposalData }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const handleClose = () => setOpen(false);
  const [btnState, setBtnState] = useState(false);
  const [platformFee, setPlatformFee] = useState();

  useEffect(() => {
    setPlatformFee(((parseInt(carData?.price) / 100) * 2.5) / 0.015);
  }, [carData]);

  const buySchema = Yup.object().shape({
    amount: Yup.string().trim().required("Please enter amount"),
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };

  const buyCar = async (data, id, proposal, isAccepted) => {
    setLoader(true);
    if (signersWallet !== undefined && addresses !== undefined) {
      const carMammasMarketplace = new ethers.Contract(
        carMamaMarketPlace.address,
        carMamaMarketPlace.abi,
        signersWallet
      );
      const usdt = new ethers.Contract(USDT.address, USDT.abi, signersWallet);
      try {
        let carId = parseInt(data?.tokenId);
        const tradeValue = ethers.utils.parseEther(data?.price.toString());
        const value = await usdt.allowance(
          addresses,
          carMamaMarketPlace.address
        );
        if (parseInt(value) < parseInt(tradeValue)) {
          const amount = ethers.utils.parseEther(
            "100000000000000"
          );
          await (await usdt.approve(carMamaMarketPlace.address, amount)).wait();
        }

        let buyVehicle;
        try {
          buyVehicle = await (
            await carMammasMarketplace.buyCar(carId, tradeValue)
          ).wait();
        } catch (error) {
        console.log("🚀 ~ buyCar ~ error:", error)
        }
        if (buyVehicle.events) {
          dispatch(
            buyCarApi({
              carAdId: carData.id,
              buyerId: userData?.id,
              proposal: proposal,
              isAccepted: isAccepted,
              offerId: id,
              setLoader: setLoader,
            })
          );
        }
      } catch (error) {
        setLoader(false);
        toast.error(error.reason);
      }
    } else {
      setLoader(false);
      toast.error("Please connect wallet");
    }
  };

  const makeOffer = async (carData, values) => {
    // setBtnState(true);
    // setLoader(true)
    // setOpen(false)
    const signingDomain = async () => {
      const domain = {
        name: "CarMammas-Offer",
        version: "1",
        verifyingContract: carMamaMarketPlace.address, // marketplace contract address
        chainId: 398, // chainid of blockchain
      };
      return domain;
    };

    const domain = await signingDomain();

    const types = {
      CarMammasMakeOffer: [
        { name: "carId", type: "uint256" },
        { name: "buyerAddress", type: "address" },
        { name: "nftAddress", type: "address" },
        { name: "priceOffered", type: "uint256" },
        { name: "timeStamp", type: "uint256" },
      ],
    };

    const price = ethers.utils.parseEther(values.amount.toString());
    const time = Math.floor(Date.now() / 1000);
    const _voucherBuyer = {
      carId: carData?.tokenId,
      buyerAddress: addresses,
      nftAddress: carMammasNFT.address,
      priceOffered: price,
      timeStamp: time,
    };

    try {
      const signature = await signersWallet._signTypedData(
        domain,
        types,
        _voucherBuyer
      );
      const verifyAddr = ethers.utils.verifyTypedData(
        domain,
        types,
        _voucherBuyer,
        signature
      );

      if (signature !== undefined) {
        const usdt = new ethers.Contract(USDT.address, USDT.abi, signersWallet);
        await (await usdt.approve(carMamaMarketPlace.address, price)).wait();
        dispatch(
          sendOffer({
            amount: values.amount,
            description: values.detail,
            addId: id,
            setOpen: setOpen,
            setLoader: setLoader,
            setBtnState: setBtnState,
            currentTime: time, // add this on database
            buyerAddress: addresses, // add this on database
            signature: signature,
          })
        );
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.reason);
    }
  };
  const formik = useFormik({
    initialValues: {
      amount: "",
      detail: "",
    },
    enableReinitialize: true,
    validationSchema: buySchema,
    onSubmit: async (values) => {
      setLoader(true);
      setOpen(false);
      if (signersWallet !== undefined && addresses !== undefined) {
        makeOffer(carData, values);
      } else {
        setLoader(false);
        toast.error("please connect with wallet first");
      }
    },
  });
  const handleAccept = async (proposalData, id, proposal) => {
    setLoader(true);
    await buyCar(proposalData, id, proposal, true);
  };
  const handleReject = (id, proposal) => {
    setLoader(true);
    dispatch(
      accpetRejectOffer({
        offerId: id,
        isAccepted: false,
        setLoader: setLoader,
        proposal: proposal,
      })
    );
  };

  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <>
          <>
            {carData?.BuyerOffers?.length > 0 &&
            carData?.BuyerOffers.some((obj) => obj.status !== "rejected") ? (
              <>
                {carData?.BuyerOffers.some(
                  (obj) => obj.userId === userData?.id
                ) ? (
                  <></>
                ) : (
                  <Card sx={{ borderRadius: 3 }}>
                    <CardHeader title="Buy Car" />
                    <div className="row">
                      <CardContent sx={{ padding: 0 }}>
                        <div className={`col-lg-12 p-2 ${styles.cardText}`}>
                          <p style={{ width: "100%" }}>
                            Amount : {carData?.price} USDT +{" "}
                            {platformFee.toFixed(2)}
                          </p>
                          {/* <p>Location here with icon</p> */}
                          {carData?.userId !== userData?.id && (
                            <>
                              {carData?.userTag === "Buyer" ? (
                                <Link
                                  to={`/CreateListing?sellCar?${carData.id}`}
                                >
                                  <Button
                                    className={`float-end ${styles.buyNow}`}
                                  >
                                    Send Proposal
                                  </Button>
                                  <br />
                                </Link>
                              ) : (
                                <>
                                  {carData?.isPurchased ? (
                                    <span>
                                      <Button>purchased</Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <span>
                                        <Button
                                          onClick={handleOpen}
                                          className="mx-4"
                                        >
                                          Send offer
                                        </Button>
                                        <Button
                                          onClick={() => buyCar(carData, false)}
                                        >
                                          Buy Car
                                        </Button>
                                      </span>
                                    </span>
                                  )}
                                  {/* <span></span> */}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </CardContent>
                    </div>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          <form
                            onSubmit={formik.handleSubmit}
                            className={styles.Form}
                          >
                            <label className="text-start">Amount</label>
                            <input
                              fullWidth
                              className={styles.input}
                              name="amount"
                              type="number"
                              placeholder="Enter Your Amount"
                              value={formik.values.amount}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.amount &&
                                Boolean(formik.errors.amount)
                              }
                              helperText={
                                formik.touched.amount && formik.errors.amount
                              }
                            />
                            {formik.touched.amount && formik.errors.amount ? (
                              <p className={styles.error}>
                                {formik.errors.amount}
                              </p>
                            ) : (
                              ""
                            )}
                            <label className="text-start">Description</label>
                            <input
                              fullWidth
                              name="detail"
                              type="detail"
                              placeholder="Enter Description"
                              className={styles.input}
                              value={formik.values.detail}
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.detail && formik.errors.detail
                              }
                            />
                            {formik.touched.detail && formik.errors.detail ? (
                              <p className={styles.error}>
                                {formik.errors.detail}
                              </p>
                            ) : (
                              ""
                            )}
                            <div className="col-lg-12">
                              <Button type="submit" disabled={btnState}>
                                Submit
                              </Button>
                            </div>
                          </form>
                        </Typography>
                      </Box>
                    </Modal>
                  </Card>
                )}
              </>
            ) : (
              <>
                {carData?.userId === userData?.id ? (
                  <>
                    {carData?.userTag === "Buyer" && (
                      <>
                        {proposalData?.Proposals?.length > 0 && (
                          <Card sx={{ borderRadius: 3 }}>
                            <CardHeader
                              title={
                                carData?.userTag === "Buyer"
                                  ? "Proposal Listing"
                                  : "Offer Listing"
                              }
                            />
                            <div className="row">
                              <CardContent sx={{ padding: 0 }}>
                                <div
                                  className={`col-lg-12 mx-4 p-2 ${styles.cardText}`}
                                >
                                  {proposalData?.Proposals?.map(
                                    (item, index) => {
                                      return (
                                        <div className={styles.offerListing}>
                                          <p>{proposalData?.User?.firstName}</p>
                                          <p>{proposalData?.User?.email}</p>
                                          <a
                                            href={`/detailCar?${item?.proposalCarAd?.id}`}
                                          >
                                            view listing
                                          </a>
                                          {item?.status === "rejected" ? (
                                            <span>Rejected</span>
                                          ) : (
                                            <>
                                              {item?.status === "accepted" ? (
                                                <span
                                                  className={`float-end mx-5`}
                                                >
                                                  Accepted
                                                </span>
                                              ) : (
                                                <>
                                                  <span
                                                    className={`float-end mx-5 ${styles.acceptButton}`}
                                                    onClick={
                                                      () =>
                                                        handleAccept(
                                                          item?.proposalCarAd,
                                                          item?.id,
                                                          true
                                                        )
                                                      // window.location.href =  `/detailCar?${item?.proposalCarAd?.id}`
                                                    }
                                                  >
                                                    Accept
                                                  </span>
                                                  <span
                                                    className={`float-start mb-3 ${styles.acceptButton}`}
                                                    onClick={() =>
                                                      handleReject(
                                                        item?.id,
                                                        true
                                                      )
                                                    }
                                                  >
                                                    Reject
                                                  </span>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </CardContent>
                            </div>
                          </Card>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Card sx={{ borderRadius: 3 }}>
                    <CardHeader
                      title={
                        carData?.userTag === "Buyer"
                          ? "Send Proposal"
                          : "Buy Car"
                      }
                    />
                    <div className="row">
                      <CardContent sx={{ padding: 0 }}>
                        <div className={`col-lg-12 p-2 ${styles.cardText}`}>
                          {/* <p>Location here with icon</p> */}
                          <>
                            {carData?.userTag === "Buyer" ? (
                              <Link to={`/CreateListing?sellCar?${carData.id}`}>
                                <Button className={`${styles.buyNow}`}>
                                  Send Proposal
                                </Button>
                                <br />
                              </Link>
                            ) : (
                              <>
                                <p style={{ width: "100%" }}>
                                  Amount : {carData?.price} USDT
                                </p>
                                {carData?.isPurchased ? (
                                  <p>
                                    <Button>purchased</Button>
                                  </p>
                                ) : (
                                  <p>
                                    <Button
                                      className="mx-2"
                                      onClick={handleOpen}
                                    >
                                      Send offer
                                    </Button>
                                    <Button
                                      onClick={() => buyCar(carData, false)}
                                    >
                                      Buy Car
                                    </Button>
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        </div>
                      </CardContent>
                    </div>
                  </Card>
                )}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      <form
                        onSubmit={formik.handleSubmit}
                        className={styles.Form}
                      >
                        <label className="text-start">Amount</label>
                        <input
                          fullWidth
                          className={styles.input}
                          name="amount"
                          type="number"
                          placeholder="Enter Your Amount"
                          value={formik.values.amount}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.amount &&
                            Boolean(formik.errors.amount)
                          }
                          helperText={
                            formik.touched.amount && formik.errors.amount
                          }
                        />
                        {formik.touched.amount && formik.errors.amount ? (
                          <p className={styles.error}>{formik.errors.amount}</p>
                        ) : (
                          ""
                        )}
                        <label className="text-start">Description</label>
                        <input
                          fullWidth
                          name="detail"
                          type="detail"
                          placeholder="Enter Description"
                          className={styles.input}
                          value={formik.values.detail}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.detail && formik.errors.detail
                          }
                        />
                        {formik.touched.detail && formik.errors.detail ? (
                          <p className={styles.error}>{formik.errors.detail}</p>
                        ) : (
                          ""
                        )}
                        <div className="col-lg-12">
                          <Button type="submit" disabled={btnState}>
                            Submit
                          </Button>
                        </div>
                      </form>
                    </Typography>
                  </Box>
                </Modal>
              </>
            )}
          </>
        </>
      )}
    </>
  );
}
