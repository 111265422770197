import React from 'react'
import {useHistory} from "react-router-dom";
import styles from "./style.module.scss"


export default function Login() {
  const history = useHistory();
  return (
    <div className={`container ${styles.Forms}`}>
    <h1>404</h1>
    <span>Page</span><br/>
    <button onClick={() => history.goBack()}>back to screen</button>
    </div>
  )
}
