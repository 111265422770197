import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Slider from "./caresoule";
import { isLogin, logout } from "../../Redux/Auth/actions";

//styles imort
import styles from "./style.module.scss";
import { Link } from 'react-router-dom';

export default function RecipeReviewCard({ data, city }) {
  let url = window.location.pathname.split("/")[1];
  const userData = useSelector((state) => state.authReducer?.login);
  return (
    <Link to={`/detailCar?${data.id}`}>
    <Card sx={{ maxWidth: 345,maxHeight:345, borderRadius: 2, border: 1, borderColor: "03D069" }}>
      <div className='row'>
        <CardContent sx={{ padding: 0 }}>
          {/* <Link to={`/detailCar?${data.id}`}> */}
            <Slider img={data?.CarAdImages} data={data} />
          {/* </Link> */}
          <div className={`col-lg-12 mx-3 p-2 ${styles.cardText}`}>
            {/* <div className={`${styles.name}`}>
              <Link to={`/detailCar?${data.id}`}>
                <span >{data?.make}</span><br />
                <span> {data?.model}</span>
              </Link>
            </div> */}
            <div>
                <Link to={`/detailCar?${data.id}`}>
              <p className="mb-1">
                  USDT {data?.price} <span className={styles.carPrice}>/Price</span>&nbsp;&nbsp;&nbsp;
                  <p className="my-1">{data?.make ? data?.make :""} - {data?.model ? data?.model :""}</p>
              </p>
              <p className={`mb-1 ${styles.favouti}`}>{data?.verient ? data?.verient : `-`}</p>
                </Link>
              {isLogin() && url === "dashboard" || data?.userId === userData?.id ? (
                <Link to={`/detailCar?${data.id}`}>
                  <span className={`float-end ${styles.checkList}`}>Check List</span>
                </Link>
              ) : (
                <>{
                  data?.userTag === "Buyer" ? (
                    <Link to={`/CreateListing?sellCar?${data.id}`}><span className={`mx-5 float-end ${styles.buyNow}`}>Send Proposal
                    </span><br /></Link>
                  ) : (
                    <Link to={`/detailCar?${data.id}`}>
                      <span className={`${styles.buyNow}`}>Buy Now</span> <span className={`float-end ${styles.sendOffer}`}><button>Send Offer</button></span> <br />
                    </Link>
                  )
                }
                </>
              )}

              {/* <span>{city ? city.replace(/%20/g, " ") : ""}</span> */}
            </div>
          </div>
        </CardContent>
      </div>
    </Card>
    </Link>
  );
}