import React,{useEffect} from 'react'

//style

import styles from "./style.module.scss"

const TermsAndCondition = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return (
        <div className={`container ${styles.Forms}`}>
            <div className='row mx-0'>
                <div className='col-lg-12 text-center'><h1>
                    Terms and Condition
                </h1>
                </div>
                <div className={`col-lg-12`}>
                    <ol>
                        <li>Scope
                            <ul>
                                <li>1.1.These terms and conditions (“Conditions'') set out the terms of use of Carmammas online website and related services (the “Platform”).</li>
                            </ul> </li>
                        <li>Parties
                            <ul>
                                <li>2.1.Carmammas operates as part of Roshini Labs Limited ("Roshini Labs”, “we", “us" or “our") which operates Carmammas online at www.carmammas.com (the “Website").</li>
                                <li>2.2.You are a participant in the acting as a Seller (“Vendor”), or a Buyer or “Bidder” ("you" or “your").</li>
                            </ul>
                        </li>
                        <li>Acceptance
                            <ul>
                                <li>3.1.By indicating your consent during the Bidder or Vendor registration process, you agree to be bound by these Conditions.</li>
                            </ul>
                        </li>
                        <li>Updates
                            <ul>
                                <li>4.1.We may change these Conditions by updating the version available on the Website (for example, for legal, economic, or other business reasons).</li>
                            </ul>
                        </li>
                        <li>Agent
                            <ul>
                                <li>5.1.We act as agent for Vendors. When a bid is Accepted a Sale Agreement is made between the Vendor and the Buyer.</li>
                            </ul>
                        </li>
                        <li>Auction participation
                            <ul>
                                <li>6.1.To participate in a purchase or sale you must:
                                    <ul>
                                        <li>6.1.1.be fully registered with us;</li>
                                        <li>6.1.2.be over the age of 18;</li>
                                        <li>6.1.3.comply with any anti-money laundering requests that we may require from time to time; and</li>
                                        <li>6.1.4.agree to comply with the Buyer’s or Vendor’s responsibilities set out in these Conditions (as applicable).</li>
                                    </ul>
                                </li>
                                <li>6.2.We reserve the right to deny, revoke or suspend the ability to participate on the Platform where a party is in breach or suspected to be in breach of these Conditions.</li>
                            </ul>
                        </li>
                        <li>Vendor’s responsibilities
                            <ul>
                                <li>7.1.Each Vendor must:
                                    <ul>
                                        <li>7.1.1.provide us with any documents or information relating to the Vehicle that we request including but not limited to the registration number, proof of ownership details and service book;</li>
                                       <li>7.1.2.deliver the Vehicle (including all keys and documentation) or allow collection by the Buyer;</li>
                                       <li>7.1.3.remove all information relating to identifiable individuals from the Vehicle (including the removal of all documentation and wiping any computer systems); and</li>

                                    </ul>
                                </li>
                                <li>7.2.To sell your Vehicle through the Platform you warrant, at the date you accept these Conditions and the date of any listing which your Vehicle is listed at, that:
                                   <ul>
                                    <li>7.2.1.you are the owner of the Vehicle;</li>
                                    <li>7.2.2.you have the right to sell the Vehicle (including all its parts) with full title guarantee;</li>
                                    <li>7.2.3.the Vehicle is free from all encumbrances and not subject to any third-party rights;</li>
                                    <li>7.2.4.the Vehicle has not been: (i) in a major accident which has resulted in the Vehicle being unroadworthy; (ii) subject to a recorded insurance write-off;</li>
                                    <li>7.2.5.the Vehicle matches the description (whether as to specification or condition) given to Carmammas or listed on Carmammas;</li>
                                    <li>7.2.6.unless otherwise disclosed to Carmammas, the Vehicle has its true and proper recorded mileage, legal registration or chassis number of the Vehicle which corresponds to the registration documents;</li>
                                    <li>7.2.7.the Vehicle is not an imported Vehicle or is declared as an imported vehicle;</li>
                                    <li>7.2.8.the Vehicle has not been used for taxi, private hire, chauffeur, or rental purposes, driving tuition or as a police Vehicle or used by a local authority; and</li>
                                    <li>7.2.9.all documents and information provided to us are true, accurate and complete.</li>
                                   </ul>

                                </li>
                                <li>7.3.Each Vendor agrees to indemnify Carmammas against any losses, costs, expenses, liabilities or claims suffered by Carmammas as a result of a Vendor’s breach of clause 7.2.</li>
                            </ul>
                        </li>
                        <li>Buyer’s responsibilities
                            <ul>
                                <li>8.1.Each Buyer must:
                                    <ul>
                                        <li>8.1.1.ensure that it provides all necessary information and proof of identity as may reasonably be required by us when the Vehicle is collected; and</li>
                                        <li>8.1.2.prior to putting the Vehicle on the road: (i) satisfy itself regarding the safety and condition of the Vehicle; and (ii) ensure the Vehicle is road legal.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                        Sale Agreement
                        <ul>
                            <li>9.1.When an offer is made by a Bidder and Accepted by a Vendor, a Sale Agreement is formed between the Buyer and the Vendor. The Sale Agreement is subject to the cancellation rights and recission provisions set out in clauses 8.1.1, 10.3, 10.4 and 11.3 of these Conditions.</li>
                            <li>9.2.Carmammas is not a party to the Sale Agreement and has no liability in respect of the Sale Agreement. Carmammas makes no warranty or guarantee that a Vendor will comply with the provisions of a Sale Agreement. Carmammas shall not be liable for any costs, claims, losses, expenses or damages incurred by the Buyer as a result of any act or omission of a Vendor.</li>
                            <li>9.3.Title to the Vehicle passes from the Vendor to the Buyer when the Buyer has paid, and the Vendor has received the Purchase Price in full.</li>
                            <li>9.4.Risk in the Vehicle passes from the Vendor to the Buyer when the Buyer’s bid is Accepted.</li>
                            <li>9.5.The Vendor’s total liability to a Buyer under or in connection with the Sale Agreement (whether in contract, tort (including negligence), misrepresentation, for breach of any duty, or otherwise) is limited to an amount equal to the Purchase Price of the Vehicle.</li>
                        </ul>
                        </li>
                        <li>Condition of Vehicles
                            <ul>
                                <li>10.1.It is a Bidder’s responsibility to satisfy itself as to the condition of a Vehicle. All of our listings are purely digital and the vehicles offered on our Platform could be located at a number of sites across the globe. As such, we strive to ensure that you are fully versed with all vehicle specifications, market data and condition including detailed reports and a full suite of images.</li>
                                <li>10.2.We do not give or make any representation, warranty or undertaking in respect of the condition of any Vehicles or any information provided on the condition of a Vehicle. Buyers acknowledge and agree that we shall not be liable for any costs, claims, losses, expenses, liabilities or damages incurred by a Buyer in relation to the Vehicle.</li>
                                <li>10.3.Subject to clause 10.4, Buyers have the right, subject to our agreement, to rescind the Sale Agreement where the age, make or model of the Vehicle has been incorrectly described on the Website.</li>
                                <li>10.4.If a Buyer wishes to rescind the Sale Agreement under clause 10.3, they must within 24 hours of having received the Vehicle from the Vendor: (i) return the Vehicle to the Vendors Premises; and (ii) notify us of the specific grounds under clause 10.3 that they are relying on to seek to rescind the Sale Agreement. We will (in our sole discretion) determine whether there are sufficient grounds to rescind the Sale Agreement.</li>
                            </ul>
                        </li>
                        <li>Payment
                            <ul>Buyer to Vendor
                                <li>11.1.Buyers must pay the Purchase Price, the Buyer’s Premium and any associated costs (as agreed between the parties) to the Vendor within 24 hours of Acceptance of its offer. An invoice for the full amount due will be provided to the Buyer.</li>
                                <li>11.2.All payments must be made by transfer on the platform.</li>
                                <li>11.3.If payment is not received by the Buyer within 24 hours after Acceptance of its offer, we reserve the right to: (i) charge an administration cost of 10 USDT per Vehicle from 24 hours after Acceptance; or (ii) cancel the Sale Agreement.</li>
                            </ul>
                            <ul>Carmammas to Vendor
                                <li>11.4.Provided that the Buyer hasn’t notified us of an intention to rescind the Sale Agreement under clause 10.4, we shall transfer the Purchase Price (less Sales Fees) to the Vendor for the Vehicle within 2 working days of us having received in full the Purchase Price from the Buyer. Each Vendor authorises Carmammas to deduct from the Purchase Price the Sale Fees.</li>
                                <li>12.5.All payments to Vendors will be made by transfer using the platform to the wallet provided during the registration process.</li>
                            </ul>
                        </li>
                        <li>Limitation of liability
                            <ul>
                                <li>12.1.Nothing in these Conditions restricts our liability to you for:</li>
                               <li>12.2.Except as set out above:
                                  <ul>
                                    <li>12.2.1.all implied terms, conditions, representations and warranties are excluded from these Conditions to the fullest extent permitted by law;</li>
                                    <li>12.2.2.we are not responsible to you for any loss or damage caused by us that is not foreseeable. We are only responsible for loss or damage that you suffer that is a reasonably foreseeable result of our failure to comply with these Conditions or our failure to use reasonable care and skill;</li>
                                    <li>12.2.3.we are not liable to you for any business losses. If you use our service for any commercial or business purpose, we will have no liability to you for loss of profit, loss of business, business interruption, loss of business opportunity or contracts, or loss of goodwill;</li>
                                    <li>12.2.4.we are not liable to you for any loss of, or damage to, data; and</li>
                                    <li>12.2.5.our total liability to you under or in connection with these Conditions (whether in contract, tort (including negligence), misrepresentation, for breach of any duty, or otherwise) is limited to an amount equal to the Purchase Price of the Vehicle, or if the Vehicle has not sold, its Market Value.</li>
                                  </ul>
                               </li>
                               <li>12.1.1.death or personal injury caused by our negligence;</li>
                               <li>12.1.2.fraud or fraudulent misrepresentation; or</li>
                               <li>12.1.3.any other liability that cannot be restricted by law.</li>
                            </ul>
                        </li>
                        <li>Events Outside Our Control
                            <ul>
                                <li>13.1.We are not in breach of these Conditions, and have no liability to you, if we cannot perform any obligation under these Conditions because of any event or circumstances beyond our reasonable control (for example, fire, flood, strike, riot, disease, pandemic, accident, disruption to utility suppliers or networks and systems, civil commotion, acts of terrorism or war, breakdown of equipment, bad weather, acts or omissions of third parties, and road traffic problems) (each an "Event Outside Our Control").</li>
                                <li>13.2.If an Event Outside Our Control happens and it affects our performance of our obligations:</li>
                                <li>13.2.1.we will let you know as soon as we reasonably can; and</li>
                                <li>13.2.2.our time to perform the affected obligations is extended for as long as the Event Outside Our Control continues.</li>
                            </ul>
                        </li>
                        <li>Personal Data
                            <li>You can find out how we use your personal data in ourPrivacy Policy.</li>
                        </li>
                        <li>General
                            <ul>
                                <li>16.1.Notices</li>
                                <li>16.1.Notices
                                    <ul>
                                        <li>16.1.1.1.email to the email address you provided; or</li>
                                        <li>16.1.1.2.post to the latest address we hold for you.</li>
                                    </ul>
                                </li>
                                <li>16.1.2.You must send any notice to vendor by:
                                    <ul>
                                        <li>16.1.2.1.Using the contact Seller tab in your membership account on  www.carmammas.com</li>
                                    </ul>
                                </li>
                                <li>16.1.3.Any notice we send is deemed received:
                                    <ul>
                                        <li>16.1.3.1.if sent by email, on transmission; or</li>
                                        <li>16.1.3.2.if sent by post, on the second working day after posting</li>
                                    </ul>
                                </li>
                                <li>16.2.Assignment. We may transfer our rights and obligations under these Conditions to another organisation. You may not transfer your rights or obligations under these Conditions to any other person.</li>
                                <li>16.3.Entire agreement. These Conditions are the entire agreement between you and us relating to its subject matter and supersedes anything previously passing between you and us relevant to that subject matter.</li>
                                <li>16.4.Representations. You confirm that, in entering these Conditions, you have not relied on anything that is not set out in writing in these Conditions.</li>
                                <li>16.5.Waiver. If we delay or fail to exercise any of our rights and remedies under the law or these Conditions, that delay or failure doesn’t constitute a waiver of that or any other right or remedy. No single or partial exercise of any of our rights or remedies restricts our ability to further exercise that or any other right or remedy.</li>
                                <li>16.6.Severability. If any of the terms of these Conditions is or becomes illegal, invalid, or unenforceable, in whole or in part, the affected term is deemed modified to the minimum extent necessary to make it legal, valid and enforceable. If that modification isn’t possible, then the affected term is deemed deleted. This does not affect the validity or enforceability of the rest of these Conditions.</li>
                                <li>16.7.Third party rights. A person who is not a party to these Conditions has no rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any of its terms.</li>
                                <li>16.8.Governing law and jurisdiction. These Conditions are governed by English law. This means that any matter or dispute arising out of or in connection with these Conditions (including non-contractual disputes or claims) will be governed by English law.</li>
                            </ul>
                        </li>
                        <li>Definitions</li>
                    </ol>
                    <p>Accept, Accepted or Acceptance means when Carmammas accepts, on behalf of the Vendor, an offer put to it by a Bidder.</p>
                    <p>Bidder means a participant in the Platform who submits bids.</p>
                    <p>Buyer means the successful bidder whose offer has been Accepted for the Vehicle.</p>
                    <p>Buyer's Premium means the service charge paid by the Buyer to Carmammas which is calculated upon final payment stage.</p>
                    <p>Market Value means the current clean valuation point for the Vehicle on the date of the sale.</p>
                    <p>Purchase Price means the bid price of the Vehicle which has been Accepted.</p>
                    <p>Sale Agreement means the agreement between Vendor and Buyer which includes the relevant provisions of these Conditions.</p>
                    <p>Sale Fees means the fees, agreed in advance between us and a Vendor, payable in relation to the provision of services.</p>
                    <p>Vehicle means a vehicle that a Vendor has listed for sale on the Platform.</p>
                    <p>Vendor means the entity that has instructed Carmammas to perform services on its behalf in relation to the sale of a Vehicle.</p>
                </div>
            </div>

        </div>
    )

}

export default TermsAndCondition;
