import React, { useState,useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword } from "../../Redux/Auth/actions"

//scss
import styles from "./style.module.scss";

const Form = ({ type }) => {
  const dispatch = useDispatch();
  const [btnState, setBtnState] = useState(false);
  const loginUserSchema = Yup.object().shape({
    email: Yup.string().trim()
      .email("Please enter valid email address")
      .required("Please enter email address"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: loginUserSchema,
    onSubmit: (values) => {
      setBtnState(true);
      dispatch(forgotPassword({
        email: values.email,
        setBtnState: setBtnState
      }));
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div className="Container my-5">
      <div className="row mx-0 mt-5">
        <div className="col-12 my-5">
        <h2 className={`mt-5 text-center ${styles.loginHeader}`}>Forget Password</h2>
          <form onSubmit={formik.handleSubmit} className={styles.Form}>
            <label className="text-start">Email</label>
            <input
              fullWidth
              className={styles.input}
              name="email"
              placeholder="Enter Your Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={formik.touched.email && formik.errors.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className={styles.error}>{formik.errors.email}</div>
            ) : (
              ""
            )}
            <button type="submit" disabled={btnState}>Request Password</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
